// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Box,
  Text,
  Button,
  Card,
  Layer,
  Anchor,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Image,
  Tabs,
  Tab,
  Heading,
} from 'grommet';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Close, DownloadOption, LinkPrevious } from 'grommet-icons';
// import CopyToClipboard from 'react-copy-to-clipboard';

import Skeleton from 'react-loading-skeleton';
import LazyLoad from 'react-lazyload';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import PlayerClip from '../Player/PlayerClip';
import { formatDateShort, getPrivacyName } from '../../helpers/utils';
import { isiOS } from '../../helpers/browserDetect';
import ClaimPlayerLayer from '../Player/ClaimPlayerLayer';
import { withFirebase } from '../../services/Firebase';
import HighlightVideoPlayer from '../../helpers/HighlightVideoPlayer';
import ShareDropdown from '../../helpers/ShareDropdown';
import Toast from '../../components/Toast';
import ResponsiveGrid from '../../helpers/ResponsiveGrid';
import Recap from './Recap';
import PlayerPreview from '../Player/PlayerPreview';
import BoxScore from './BoxScore';
import useGame from './useGame';
import HlsPlayer from '../../components/HLS/HlsPlayer';
import PrivacyAwareYTLink from '../../components/PrivacyAwareYTLink';
import { analytics } from '../../services/analytics';
import PlayByPlay from './PlayByPlay/PlayByPlay';
import usePlayByPlay from './PlayByPlay/usePlayByPlay';
import { useIsEntitledToViewGame } from './gameHooks';
import Paywall from '../../components/Paywall/Paywall';
import { AppHostContext } from '../../services/appHost';
import { usePaywall } from '../../components/Paywall/paywall.hooks';
import PaywallLock from '../../components/Paywall/PaywallLock';

const Game = ({ history, match, ...props }) => {
  const {
    showOpponentStatus,
    players,
    isEmbed,
    activeClip,
    setActiveClip,
    setShowVideo,
    gameId,
    userStore,
    closeVideo,
    isTeamAdmin,
    gameData,
    setGameData,
    loading,
    showPlayerPreview,
    highlightPlayerId,
    setShowPlayerPreview,
    size,
    gameSummaries,
    gameSummaryTabIndex,
    setGameSummaryTabIndex,
    activeTabIndex,
    setActiveTabIndex,
    availableTabs,
    boxScoreData,
    recapData,
    showVideo,
    hlsResponse,
  } = useGame(history, match, 'bbsb', props.gameData);

  const appHost = useContext(AppHostContext);

  const { status: viewerEntitlementStatus } = useIsEntitledToViewGame(gameData);

  const {
    isPaywallActive,
    handlePaywallOpen,
    handlePaywallClose,
    handleUnlockAccess,
  } = usePaywall();
  const [showLockModal, setShowLockModal] = useState(false);

  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [showUnclaimedModal, setShowUnclaimedModal] = useState(false);
  const [showClaim, setShowClaim] = useState(false);

  const { clips, setClips } = usePlayByPlay(gameId);
  const [showDownloadInstructions, setShowDownloadInstructions] =
    useState(false);
  const [copied, setCopied] = useState(false);

  const sendNavigationPayload = (destination) => {
    const payload = {
      message: 'navigation',
      destination,
    };

    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.navigation
    ) {
      window.webkit.messageHandlers.navigation.postMessage(payload);
    }
  };

  const handleNavigation = useCallback(
    (destination) => {
      if (isEmbed) {
        sendNavigationPayload(destination);
      } else {
        history.push(destination);
      }
    },
    [isEmbed, history]
  );

  const renderScoringByInning = (data) => {
    const {
      J_opponentName,
      scoreOurName,
      scoreWeAreHome,
      homeScore,
      awayScore,
      homeRunsByInning,
      awayRunsByInning,
    } = data;

    let homeTeamName;
    let awayTeamName;

    if (scoreWeAreHome) {
      homeTeamName = scoreOurName;
      awayTeamName = J_opponentName;
    } else {
      homeTeamName = J_opponentName;
      awayTeamName = scoreOurName;
    }

    let headers;
    if (homeRunsByInning.length > awayRunsByInning.length) {
      headers = ['Team']
        .concat(homeRunsByInning.map((_, inning) => inning + 1))
        .concat(['R']);
    } else {
      headers = ['Team']
        .concat(awayRunsByInning.map((_, inning) => inning + 1))
        .concat(['R']);
    }

    const homeRow = [homeTeamName]
      .concat(homeRunsByInning.map((runs) => runs))
      .concat([homeScore]);
    const awayRow = [awayTeamName]
      .concat(awayRunsByInning.map((runs) => runs))
      .concat([awayScore]);

    const scoresTable = (
      <Table>
        <TableHeader>
          <TableRow>
            {headers.map((period) => (
              <TableCell
                border="bottom"
                key={`period-header-${period}`}
                scope="col"
              >
                <Text size="small" weight="bold">
                  {period}
                </Text>
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow key="away-row">
            {awayRow.map((x, i) => {
              const isBold = i === awayRow.length - 1 && i !== 0;
              const color = i === 0 || i === awayRow.length - 1 ? '' : 'dark-4';
              let label;
              if (size === 'small' && i === 0 && !scoreWeAreHome) {
                label = (
                  <Anchor
                    onClick={() => {
                      const destination = `/${gameData.DNOR_teamId}`;
                      if (isEmbed) {
                        sendNavigationPayload(destination);
                      } else {
                        history.push(destination);
                      }
                    }}
                  >
                    <Text
                      size="small"
                      weight={isBold ? 'bold' : 'normal'}
                      color={color}
                    >
                      {x}
                    </Text>
                  </Anchor>
                );
              } else {
                label = (
                  <Text
                    size="small"
                    weight={isBold ? 'bold' : 'normal'}
                    color={color}
                  >
                    {x}
                  </Text>
                );
              }
              return (
                <TableCell border={false} key={`away-runs-${i + 1}`}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow key="home-row">
            {homeRow.map((x, i) => {
              const isBold = i === homeRow.length - 1 && i !== 0;
              const color = i === 0 || i === homeRow.length - 1 ? '' : 'dark-4';
              let label;
              if (size === 'small' && i === 0 && scoreWeAreHome) {
                label = (
                  <Anchor
                    onClick={() => {
                      const destination = `/${gameData.DNOR_teamId}`;
                      if (isEmbed) {
                        sendNavigationPayload(destination);
                      } else {
                        history.push(destination);
                      }
                    }}
                  >
                    <Text
                      size="small"
                      weight={isBold ? 'bold' : 'normal'}
                      color={color}
                    >
                      {x}
                    </Text>
                  </Anchor>
                );
              } else {
                label = (
                  <Text
                    size="small"
                    weight={isBold ? 'bold' : 'normal'}
                    color={color}
                  >
                    {x}
                  </Text>
                );
              }
              return (
                <TableCell border={false} key={`home-runs-${i + 1}`}>
                  {label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    );

    return <Box margin={{ top: 'medium' }}>{scoresTable}</Box>;
  };

  const renderVideoLayer = () => {
    const taggedPlayers = activeClip.J_teamPlayerList.map(
      (pId) => players[pId]
    ).filter((x) => x);

    return (
      <Layer onEsc={closeVideo}>
        <Box width="large">
          <Box direction="row" justify="end" fill="horizontal">
            <Button icon={<Close />} onClick={closeVideo} hoverIndicator />
          </Box>
          {!isTeamAdmin() && (
            <Card
              elevation="none"
              gap="small"
              background="light-3"
              pad="medium"
            >
              <Text>
                {`There are
                ${Object.entries(clips)
                  .map((obj) => obj[1].length)
                  .reduce((a, b) => a + b, 0)} video clips for this game.
                At-bat videos are only available to ${
                  gameData.scoreOurName
                } players, coaches, and team managers.`}
              </Text>
              <Button
                primary
                label="Login"
                onClick={() => {
                  history.push('/signin');
                }}
              />
            </Card>
          )}
          <PlayerClip
            key={activeClip.id}
            clip={activeClip}
            game={gameData}
            playerId={activeClip.J_claimedPlayerList[0]}
            teamPlayerName={
              taggedPlayers.length > 0
                ? getPrivacyName(
                    taggedPlayers[0].nameFirst,
                    taggedPlayers[0].nameLast,
                    taggedPlayers[0].embedNameFirstLevel,
                    taggedPlayers[0].embedNameLastLevel
                  )
                : ''
            }
            publicPublishLevel={isTeamAdmin() ? 10 : -1}
            onChange={(newClip) => {
              const newGameData = { ...gameData };
              const clipsByInning = {};
              // eslint-disable-next-line no-restricted-syntax
              for (const [inning, inningClips] of Object.entries(clips)) {
                const newClips = inningClips.map((clip) => {
                  if (clip.id === newClip.id) {
                    return newClip;
                  }
                  return clip;
                });

                clipsByInning[inning] = newClips;
              }
              setClips(clipsByInning);

              setGameData(newGameData);
              closeVideo();
            }}
            hideBottomBar={!isTeamAdmin()}
            fromGamePage
          />
        </Box>
      </Layer>
    );
  };

  const unclaimedModal = (
    <Layer onEsc={() => setShowUnclaimedModal(false)}>
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <Button
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowUnclaimedModal(false)}
          />
        </Box>
        <Box margin="medium" gap="large">
          <Text>
            This player is not claimed yet. Players must be claimed by their
            families before highlights can be viewed, shared, or downloaded.
          </Text>
          {selectedPlayerId && (
            <Button
              primary
              color="tertiary-0"
              id="claim-this-player"
              size="large"
              label={<Text weight="bold">Claim this player</Text>}
              onClick={() => {
                setShowClaim(true);
                setShowUnclaimedModal(false);
              }}
            />
          )}
        </Box>
      </Box>
    </Layer>
  );

  useEffect(() => {
    if (activeClip && !activeClip.description) {
      const params = {
        playerIdOrHandle: activeClip.J_teamPlayerList[0],
      };
      axios
        .get(`/api/clip/${activeClip.id}/description`, { params })
        .then((response) => {
          setActiveClip({
            ...activeClip,
            description: response.data.ogTags.st,
          });
        })
        .catch((e) => {
          Sentry.captureException(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeClip]);

  const downloadInstructions = (
    <Layer onEsc={() => setShowDownloadInstructions(false)}>
      <Box>
        <Box direction="row" justify="end" fill="horizontal">
          <Button
            hoverIndicator
            icon={<Close />}
            onClick={() => setShowDownloadInstructions(false)}
          />
        </Box>
        <Box margin="small">
          <Text>
            Your video will appear in the download list in the top right corner
            of the screen after you press the down arrow.
          </Text>
          <Image src="/download_clip_help.png" />
        </Box>
      </Box>
    </Layer>
  );

  const handleShare = () => {
    const link = `https://sidelinehd.com/game/${gameId}?utm_source=mobile_share`;

    if (navigator.share) {
      analytics.track('Game Summary Share Clicked', {
        gameId,
        loggedIn: !!userStore.authUser,
        type: 'share via',
        sport: 'bbsb',
      });
      navigator
        .share({
          title: `${gameSummaries.gameSummary.titleMain}`,
          text: `Highlights for ${gameSummaries.gameSummary.titleMain}`,
          url: link,
        })
        .catch((respError) => {
          Sentry.captureException(respError);
        });
    }
  };

  const renderGameSummaryVideo = (gameSummary, teamName) => (
    <Tab title={teamName}>
      <Card elevation="medium">
        <LazyLoad
          once
          placeholder={
            <Box fill="horizontal" style={{ height: '200px' }}>
              <Skeleton height={200} duration={0.8} />
            </Box>
          }
        >
          <HighlightVideoPlayer
            id={gameSummary._id}
            hvid={gameSummary}
            category={gameSummary.category}
            videoSrc={gameSummary.fileUrl720}
            location="game_page"
            autoplay
          />
        </LazyLoad>
        <Box pad="small">
          <Box direction="row" justify="between">
            <Box
              pad="small"
              direction="row"
              gap="small"
              align="center"
              fill="horizontal"
              justify="end"
            >
              <Box gap="large" direction="row" align="center">
                <Button
                  primary
                  color="tertiary-1"
                  label={<Text size="small">Give us feedback!</Text>}
                  href={`https://docs.google.com/forms/d/e/1FAIpQLSeDNE5PGYrmOmQ8UI5FyfuMkM7MEW2Gkgu-MqhCDUIjJkmI3A/viewform?usp=pp_url&entry.1066612687=${gameId}`}
                  target="_blank"
                />
                <Button
                  plain
                  color="secondary-1"
                  icon={<DownloadOption size="20px" color="secondary-1" />}
                  label={<Text size="small">Download</Text>}
                  href={gameSummary.fileUrl720}
                  id="download-clip"
                  onClick={() => {
                    analytics.track('Game Summary Download Clicked', {
                      gameId,
                      loggedIn: !!userStore.authUser,
                      sport: 'bbsb',
                    });
                    setShowDownloadInstructions(true);
                  }}
                />
                <ShareDropdown
                  plain
                  label={<Text size="small">Share</Text>}
                  color="secondary-1"
                  renderShareOptions={() => {
                    const link = `https://sidelinehd.com/game/${gameId}?utm_source=copy_link#highlights`;
                    return (
                      <Box pad="medium" gap="small">
                        {size === 'small' && (
                          <Button
                            label="Share highlight via ..."
                            onClick={handleShare}
                          />
                        )}
                        <CopyToClipboard
                          text={link}
                          onCopy={() => {
                            analytics.track('Game Summary Share Clicked', {
                              gameId,
                              loggedIn: !!userStore.authUser,
                              type: 'copy',
                              sport: 'bbsb',
                            });
                            setCopied(true);
                          }}
                        >
                          <Button label="Copy link to clip" />
                        </CopyToClipboard>
                      </Box>
                    );
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Tab>
  );

  const renderGameSummaries = () => {
    if (!gameSummaries) {
      return null;
    }

    const { gameSummary, opponentGameSummary } = gameSummaries;

    const { scoreOurName, J_opponentName } = gameData;

    return (
      <Tabs
        activeIndex={gameSummaryTabIndex}
        onActive={(nextIndex) => {
          setGameSummaryTabIndex(nextIndex);
        }}
      >
        {gameSummary && renderGameSummaryVideo(gameSummary, scoreOurName)}
        {opponentGameSummary &&
          showOpponentStatus > 0 &&
          renderGameSummaryVideo(opponentGameSummary, J_opponentName)}
      </Tabs>
    );
  };

  const hitscopeButton = (
    <Box gap="medium" pad="medium">
      <Text weight="bold">HitScope Game Summary</Text>
      <Text>AI-generated video game summary</Text>
      <Button
        primary
        label="Watch now"
        onClick={() => {
          // window.open(`/hitscope/game/${gameId}?us=1&them=0`, '_blank');

          const destination = `/hitscope/game/${gameId}?us=1&them=0`;
          if (isEmbed) {
            sendNavigationPayload(destination);
          } else {
            history.push(destination);
          }
        }}
      />
    </Box>
  );

  const renderHeader = (data) => {
    const {
      J_opponentName,
      J_tsStart,
      fb,
      yt,
      scoreOurName,
      scoreWeAreHome,
      homeScore,
      awayScore,
      teamWinnerNAH,
    } = data;

    const awayTeamDiv = (
      <Box
        direction="row"
        alignSelf="center"
        align="center"
        justify="end"
        gap="medium"
        margin={{ right: 'medium' }}
        width="200px"
      >
        {scoreWeAreHome ? (
          <Text
            weight="bold"
            size="xlarge"
            alignSelf="center"
            margin={{ right: 'small' }}
          >
            {scoreWeAreHome ? J_opponentName : scoreOurName}
          </Text>
        ) : (
          <Anchor
            label={
              <Text
                weight="bold"
                size="xlarge"
                alignSelf="center"
                margin={{ right: 'small' }}
              >
                {scoreWeAreHome ? J_opponentName : scoreOurName}
              </Text>
            }
            onClick={() => {
              const destination = `/${gameData.DNOR_teamId}`;
              if (isEmbed) {
                sendNavigationPayload(destination);
              } else {
                history.push(destination);
              }
            }}
          />
        )}
        <Heading color={teamWinnerNAH !== 1 ? 'dark-4' : ''}>
          {awayScore}
        </Heading>
        {/* {teamWinnerNAH === 1 && <CaretLeftFill />} */}
      </Box>
    );

    const homeTeamDiv = (
      <Box
        direction="row"
        alignSelf="center"
        align="center"
        gap="medium"
        margin={{ left: 'medium' }}
        width="200px"
      >
        {/* {teamWinnerNAH === 2 && <CaretRightFill />} */}
        <Heading color={teamWinnerNAH !== 2 ? 'dark-4' : ''}>
          {homeScore}
        </Heading>
        {scoreWeAreHome ? (
          <Anchor
            label={
              <Text
                weight="bold"
                size="xlarge"
                alignSelf="center"
                margin={{ left: 'small' }}
              >
                {scoreWeAreHome ? scoreOurName : J_opponentName}
              </Text>
            }
            onClick={() => {
              const destination = `/${gameData.DNOR_teamId}`;
              if (isEmbed) {
                sendNavigationPayload(destination);
              } else {
                history.push(destination);
              }
            }}
          />
        ) : (
          <Text
            weight="bold"
            size="xlarge"
            alignSelf="center"
            margin={{ left: 'small' }}
            onClick={() => {
              if (scoreWeAreHome) {
                const destination = `/${gameData.DNOR_teamId}`;
                if (isEmbed) {
                  sendNavigationPayload(destination);
                } else {
                  history.push(destination);
                }
              }
            }}
          >
            {scoreWeAreHome ? scoreOurName : J_opponentName}
          </Text>
        )}
      </Box>
    );

    return (
      <Box>
        <Box pad={{ top: '16px', left: '16px' }} margin={{ bottom: '-24px' }}>
          <Box
            alignSelf="start"
            round="full"
            elevation="small"
            pad="small"
            background="white"
            onClick={() => history.goBack()}
          >
            <LinkPrevious size="24px" />
          </Box>
        </Box>
        <Box direction="row" gap="medium" margin="0 auto">
          {size !== 'small' && awayTeamDiv}
          <Box pad={{ top: 'small' }}>
            <Box direction="row" gap="small">
              <Text>{formatDateShort(J_tsStart)}</Text>
              <Text weight="bold">
                {data.scoreGameState === 50 ? 'FINAL' : 'Live'}
              </Text>
            </Box>
            {data.scoreGameState > 10 && renderScoringByInning(data)}
          </Box>
          {size !== 'small' && homeTeamDiv}
        </Box>
        <Box margin="0 auto" pad="small">
          <Box direction="row" gap="small">
            {yt && (
              <PrivacyAwareYTLink
                teamId={gameData.DNOR_teamId}
                gameId={gameId}
                ytAsset={yt}
              >
                <Button primary color="#ff0000" label="Watch on YouTube" />
              </PrivacyAwareYTLink>
            )}
            {fb && (
              <Button
                primary
                color="#4267B2"
                label="Watch on Facebook"
                href={fb.cdnUrl}
                target="_blank"
              />
            )}
          </Box>
        </Box>
        <Tabs
          activeIndex={activeTabIndex}
          onActive={(nextIndex) => {
            setActiveTabIndex(nextIndex);
          }}
        >
          {availableTabs.map((tab) => {
            switch (tab) {
              case 'boxScore':
                return <Tab key={tab} title="Box Score" />;
              case 'pbp':
                return <Tab key={tab} title="Play-by-Play" />;
              case 'recap':
                return <Tab key={tab} title="Recap" />;
              case 'highlights':
                return <Tab key={tab} title="Highlights" />;
              case 'vod':
                return <Tab title="VOD" />;
              default:
                return null;
            }
          })}
        </Tabs>
      </Box>
    );
  };

  const renderBoxScoreData = () => {
    if (
      boxScoreData.hittingBoxScore.length === 0 &&
      boxScoreData.pitchingBoxScore.length === 0
    ) {
      return (
        <Box
          direction="row"
          justify="center"
          align="center"
          height={{ min: 'large' }}
          fill
        >
          <Text>No players were tagged in this game</Text>
        </Box>
      );
    }

    return (
      <Box gap="large" pad={{ top: 'medium' }}>
        <Box gap="small">
          <BoxScore
            players={players}
            columns={boxScoreData.hittingColumns}
            data={boxScoreData.hittingBoxScore}
            gameData={gameData}
            playerCol="Hitters"
          />
        </Box>
        <Box gap="small">
          <BoxScore
            players={players}
            columns={boxScoreData.pitchingColumns}
            data={boxScoreData.pitchingBoxScore}
            gameData={gameData}
            playerCol="Pitchers"
          />
        </Box>
        <Button
          primary
          color="tertiary-1"
          size="large"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 999,
          }}
          label="Give us feedback!"
          href={`https://docs.google.com/forms/d/1nopdAX9PZFpNdUWLtJM1I4A4_1xk-J30QcbpWW-3f6U/viewform?entry.1649161831=${gameId}`}
          target="_blank"
        />
      </Box>
    );
  };

  const renderMain = (data) => (
    <Box height="100%">
      {availableTabs[activeTabIndex] === 'recap' && (
        <Box background="light-1">
          {recapData && players && (
            <Recap players={players} data={recapData} canEdit={isTeamAdmin()} />
          )}
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'boxScore' && (
        <Box background="light-1">
          {boxScoreData && players && renderBoxScoreData()}
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'pbp' && (
        <Box background="light-1">
          <Box margin="small" gap="small" fill="vertical">
            {clips && data && hitscopeButton}
            <Box
              height="2px"
              background="dark-5"
              margin={{ bottom: 'small' }}
            />
            {clips && data && players ? (
              <PlayByPlay
                clips={clips}
                gameId={gameId}
                players={players}
                setShowVideo={setShowVideo}
                setShowLockModal={setShowLockModal}
                handlePaywallOpen={() => {
                  if (appHost.isHosted) {
                    appHost.service.notifications.showPaywall();
                  } else {
                    handlePaywallOpen();
                  }
                }}
                handleNavigation={handleNavigation}
                setActiveClip={setActiveClip}
                setSelectedPlayerId={setSelectedPlayerId}
                setShowUnclaimedModal={setShowUnclaimedModal}
                viewerEntitlementStatus={viewerEntitlementStatus}
                trackEvent={{
                  gameId,
                  loggedIn: !!userStore.authUser,
                  sport: 'bbsb',
                }}
              />
            ) : (
              <BasicLoader />
            )}
          </Box>
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'highlights' && (
        <Box background="light-1">
          {gameSummaries && gameSummaries.gameSummary && (
            <Box margin="small" fill>
              {data ? renderGameSummaries() : <BasicLoader />}
            </Box>
          )}
        </Box>
      )}
      {availableTabs[activeTabIndex] === 'vod' && (
        <Box background="light-1">
          <Box margin="small" fill="vertical">
            <HlsPlayer
              hlsInfo={{ type: 'data', data: hlsResponse.m3u8 }}
              gameId={gameId}
            />
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Box fill>
      {loading && <BasicLoader />}
      <ResponsiveGrid
        fill="horizontal"
        responsive
        rows={['auto', 'auto']}
        columns={['10%', '10%', '60%', '10%', '10%']}
        areas={{
          small: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'main', start: [0, 1], end: [4, 1] },
          ],
          medium: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [0, 1] },
            { name: 'main', start: [1, 1], end: [3, 1] },
            { name: 'right-bar', start: [4, 1], end: [4, 1] },
          ],
          large: [
            { name: 'header', start: [0, 0], end: [4, 0] },
            { name: 'left-bar', start: [0, 1], end: [1, 1] },
            { name: 'main', start: [2, 1], end: [2, 1] },
            { name: 'right-bar', start: [3, 1], end: [4, 1] },
          ],
        }}
      >
        <Box gridArea="header">{gameData && renderHeader(gameData)}</Box>
        <Box gridArea="left-bar" background="light-1" />
        <Box gridArea="main" background="light-1">
          {gameData && renderMain(gameData)}
        </Box>
        <Box gridArea="right-bar" background="light-1" />
      </ResponsiveGrid>
      {showDownloadInstructions && isiOS() ? downloadInstructions : null}
      {showVideo && renderVideoLayer()}
      {showUnclaimedModal && unclaimedModal}
      {showClaim && (
        <ClaimPlayerLayer
          teamPlayerId={selectedPlayerId}
          closeLayer={() => setShowClaim(false)}
        />
      )}
      {copied && (
        <Toast
          label="Copied to clipboard!"
          background="status-ok"
          duration={3000}
          onClose={() => {
            setCopied(false);
          }}
        />
      )}
      {showPlayerPreview && (
        <PlayerPreview
          playerId={highlightPlayerId}
          gameId={gameId}
          onClose={() => {
            setShowPlayerPreview(false);
          }}
          isBBSB
        />
      )}
      <Paywall
        isOpen={isPaywallActive}
        onClose={() => handlePaywallClose()}
        onUnlockAccess={(term) => handleUnlockAccess(term)}
      />
      {showLockModal && (
        <Layer
          onEsc={() => setShowLockModal(false)}
          onClickOutside={() => setShowLockModal(false)}
        >
          <Box pad="medium">
            <Box direction="row" justify="end" fill="horizontal">
              <Button
                icon={<Close />}
                onClick={() => setShowLockModal(false)}
              />
            </Box>
            <PaywallLock
              mode="game"
              isLoggedIn={!!userStore.authUser}
              isPrivate={false}
            />
          </Box>
        </Layer>
      )}
    </Box>
  );
};

const GamePage = compose(withRouter, withFirebase)(Game);

export default GamePage;
