/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import './index.css';
import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { normalize } from '@sentry/utils';
import mixpanel from 'mixpanel-browser';
import App from './components/App';
import Crash from './components/Crash';
import * as serviceWorker from './serviceWorker';
import { FirebaseContextProvider } from './services/Firebase';
import { UserContext } from './services/Session/context';
import UserStore from './services/Session/store';
import FlagsProvider from './services/FlagsProvider/FlagsProvider';
import ScoringStore from './features/Scoring/store';
import VBScoringStore from './features/Scoring/vbStore';
import GenericScoringStore from './features/Scoring/genericStore';
import {
  GenericScoringContext,
  ScoringContext,
  VBScoringContext,
} from './features/Scoring/context';
import StaticRedirects, {
  YouTubeRedirect,
} from './features/StaticRedirects/StaticRedirects';
import reportWebVitals from './reportWebVitals';
import { strogging } from '@shd/jslib/infra';
import { initTelemetry } from './services/sendTelemetry';
import GrommetContainer from './helpers/GrommetContainer';
import { AppHostContextProvider, theGlobalAppHost } from './services/appHost';
import { Auth0WrapperProvider } from './services/auth0';
import { ToastProvider } from './contexts/ToastContext';
import Toast from './components/ToastV2';

if (!theGlobalAppHost().isHosted) {
  if (process.env.NODE_ENV === 'production') {
    mixpanel.init('4fc5defbda6994fcdfae0a7a4f08e71f', { debug: false });
  } else {
    mixpanel.init('3cd58873e307070e991be11110b7f566', { debug: true });
  }
  initTelemetry();
} // else mixpanel stuff is vectored through the app host

if (process.env.NODE_ENV === 'development') {
  strogging.setLoggingLevel(strogging.LoggingLevel.DEBUG);
}

Sentry.init({
  dsn: 'https://abea0fd30ce84383b525f1e8b9a583da@o545530.ingest.sentry.io/5667683',
  debug: process.env.NODE_ENV === 'development',
  environment: process.env.NODE_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.1,
  beforeSend(event) {
    const eventForSend = normalize(event);
    if (eventForSend.exception) {
      // just keep a few frames of the stacktrace
      const ex = {
        ...eventForSend.exception,
        values: eventForSend.exception.values.map((v: any) => {
          const { stacktrace, ...vothers } = v;
          return vothers;
        }),
      };
      strogging.error('Sentry.beforeSend', { ex });
    }
    return eventForSend;
  },
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

const fbLocalConfig = {
  appId: '500198244305475',
  // appId: '230666154492793',
};

const fbProdConfig = {
  appId: '230666154492793',
};

const config: any = {};
// eslint-disable-next-line no-constant-condition
if (process.env.NODE_ENV === 'production') {
  config.env = 'production';
  config.fbConfig = fbProdConfig;
} else if (process.env.NODE_ENV === 'development') {
  config.fbConfig = fbLocalConfig;
} else {
  config.fbConfig = fbLocalConfig;
}

const initFacebookSdk = () =>
  new Promise(() => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: config.fbConfig.appId,
        cookie: true,
        xfbml: true,
        version: 'v9.0',
      });

      // // auto authenticate with the api if already logged in with facebook
      // window.FB.getLoginStatus(({ authResponse }) => {
      //   if (authResponse) {
      //     resolve();
      //     // accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
      //   } else {
      //     resolve();
      //   }
      // });
    };

    // load facebook sdk script
    // eslint-disable-next-line func-names
    (function (d, s, id) {
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js: any = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });

initFacebookSdk();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <Helmet>
      <title>sidelineHD | livestream like a PRO</title>
      <meta
        name="description"
        content="Add LIVE scoreboards to your baseball and softball livestreams."
      />
    </Helmet>
    <FirebaseContextProvider>
      <FlagsProvider>
        <ToastProvider>
          <ScoringContext.Provider value={new ScoringStore()}>
            {/* we are just using vbStore.js right now,
          but if there are store-specific bugs for v1,
          we can separate this out into vbStore.js and vbStoreTemp.js */}
            <VBScoringContext.Provider value={new VBScoringStore()}>
              <GenericScoringContext.Provider value={new GenericScoringStore()}>
                <UserContext.Provider value={new UserStore()}>
                  <AppHostContextProvider>
                    <GrommetContainer>
                      <Router>
                        <Sentry.ErrorBoundary
                          fallback={<Crash />}
                          onError={(...args) =>
                            strogging.error('errorboundary', { args })
                          }
                        >
                          <QueryParamProvider adapter={ReactRouter5Adapter}>
                            <App />
                            <StaticRedirects />
                            <YouTubeRedirect />
                            <Toast />
                          </QueryParamProvider>
                        </Sentry.ErrorBoundary>
                      </Router>
                    </GrommetContainer>
                  </AppHostContextProvider>
                </UserContext.Provider>
              </GenericScoringContext.Provider>
            </VBScoringContext.Provider>
          </ScoringContext.Provider>
        </ToastProvider>
      </FlagsProvider>
    </FirebaseContextProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
