import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  FormField,
  Image,
  Layer,
  ResponsiveContext,
  Select,
  Text,
} from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import React, { useContext, useState } from 'react';
import Compressor from 'compressorjs';
import * as Sentry from '@sentry/browser';
import {
  FieldJerseyNum,
  FieldNameFirst,
  FieldNameLast,
} from '../../helpers/FormFields';
import TagInput from '../../helpers/TagInput';
import SHDButton from '../../components/SHD/Button';
import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import Toast from '../../components/Toast';
import { shd } from '@shd/jslib/models';
import ImagePickerCropper from '../../helpers/ImagePickerCropper';
import { AxiosError } from 'axios';

const getNameStyleValue = (player: shd.TeamPlayer) => {
  if (player.embedNameFirstLevel === 2 && player.embedNameLastLevel === 2) {
    return 40;
  }
  if (player.embedNameFirstLevel === 2 && player.embedNameLastLevel === 1) {
    return 30;
  }
  if (player.embedNameFirstLevel === 1 && player.embedNameLastLevel === 2) {
    return 20;
  }
  if (player.embedNameFirstLevel === 1 && player.embedNameLastLevel === 1) {
    return 10;
  }
  return 0;
};

interface EditingPlayer extends shd.ClaimedPlayer {
  imageBlob?: Blob;
}

interface Props {
  create: boolean;
  teamPlayer?: shd.TeamPlayer;
  claimedPlayer?: EditingPlayer;
  onTeamPlayerUpdated: (player: shd.TeamPlayer) => void;
  onClaimedPlayerUpdated?: (player: EditingPlayer) => void;
  onClose: () => void;
  isTeamAdmin?: boolean;
}

const EditPlayerLayer: React.FC<Props> = ({
  create,
  teamPlayer: initialTeamPlayer,
  claimedPlayer: initialClaimedPlayer,
  onTeamPlayerUpdated,
  onClaimedPlayerUpdated,
  onClose,
  isTeamAdmin,
}) => {
  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);

  const submitButtonRef = React.useRef<HTMLButtonElement>(null);

  const [teamPlayer, setTeamPlayer] = useState<shd.TeamPlayer | undefined>(
    initialTeamPlayer
  );
  const [claimedPlayer, setClaimedPlayer] = useState<EditingPlayer | undefined>(
    initialClaimedPlayer
  );
  const [imageBlob, setImageBlob] = useState<Blob | undefined>();
  const [isValid, setIsValid] = useState(true);
  const editingDisabled = !!claimedPlayer;
  const [editingDisabledToast, setEditingDisabledToast] = useState<shd.Toast>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showRemovePrompt, setShowRemovePrompt] = useState(false);

  const [existingClips, setExistingClips] = useState();

  const [src, setSrc] = useState<string | null>(null);
  const [showImageCropper, setShowImageCropper] = useState(false);

  const isBBSB =
    userStore.selectedTeam?.attrib_sportType.toLowerCase() === 'softball' ||
    userStore.selectedTeam?.attrib_sportType.toLowerCase() === 'baseball';

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result as string);
        setShowImageCropper(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSelectedImage = async (image: File | undefined) => {
    if (!teamPlayer || !image) {
      return;
    }
    const compressedImage = await new Promise<Blob>((resolve, reject) => {
      new Compressor(image, {
        maxWidth: 1920,
        success: resolve,
        error: reject,
      });
    });
    setImageBlob(compressedImage);
    setShowImageCropper(false);
  };

  const onSave = async (isPrivacyTethered = true) => {
    try {
      setLoading(true);

      let newImage = null;
      if (imageBlob) {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            responseType: 'arraybuffer',
          },
        };

        const formData = new FormData();
        formData.append('image', imageBlob, 'name');
        formData.append('team_admin', 'true');

        const imageResponse = await axios.post(
          `/api/player/${claimedPlayer?._id}/image`,
          formData,
          config
        );
        newImage = imageResponse.data;
      }

      const newPlayer = {
        ...teamPlayer,
        isPrivacyTethered,
        invitePhoneNumberList: Array.from(
          new Set(teamPlayer?.invitePhoneNumberList)
        ),
      };

      let playerResponse;
      if (teamPlayer?._id) {
        playerResponse = await axios.put(`/api/team_player/${newPlayer._id}`, {
          player: newPlayer,
        });
      } else {
        // create new player
        playerResponse = await axios.post(
          `/api/team/${userStore.selectedTeam?._id}/team_players`,
          {
            player: newPlayer,
            teamId: userStore.selectedTeam?._id,
          }
        );
      }

      const newSelectedPlayer = playerResponse.data;

      if (newImage && claimedPlayer) {
        setClaimedPlayer({ ...claimedPlayer, DNOR_imgUrlT150: newImage });
        onClaimedPlayerUpdated &&
          onClaimedPlayerUpdated({
            ...claimedPlayer,
            DNOR_imgUrlT150: newImage,
          });
      }

      onTeamPlayerUpdated(newSelectedPlayer.player);
      onClose();
    } catch (e) {
      const axiosError = e as AxiosError;
      const errorMessage =
        'Something went wrong. Please refresh and try again.';
      setError(errorMessage);
      Sentry.captureException(axiosError);
    } finally {
      setLoading(false);
    }
  };

  const onDeactivate = () => {
    if (teamPlayer) {
      teamPlayer.isActive = false;
      onSave(false);
    }
  };

  const onPhoneNumberChanged = (value: string[]) => {
    if (teamPlayer) {
      teamPlayer.invitePhoneNumberList = value;
      setIsValid(true);
      setTeamPlayer({ ...teamPlayer });
    }
  };

  const onDelete = () => {
    axios
      .delete(`/api/team_player/${teamPlayer?._id}`, {
        data: {
          teamId: userStore.selectedTeam?._id,
          player: teamPlayer,
        },
      })
      .then(() => {
        onClose();
        window.location.reload();
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  };

  const onDeletePrompt = () => {
    axios
      .get(`/api/player/${teamPlayer?._id}/clips_old?count_only=true`)
      .then((response) => {
        const { count } = response.data;
        setExistingClips(count);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
    setShowRemovePrompt(true);
  };

  const removePrompt = () => {
    let promptText;
    if (existingClips === undefined) {
      promptText = 'Loading...';
    } else if (existingClips === 0) {
      promptText = 'Are you sure you want to delete?';
    } else {
      promptText = (
        <>
          {`There are ${existingClips} existing clips for`}{' '}
          <Text weight="bold">{`${teamPlayer?.nameFirst} ${teamPlayer?.nameLast}.`}</Text>{' '}
          These will be deleted permanently.
        </>
      );
    }
    return (
      <Box
        gap="medium"
        pad="small"
        margin={{ top: 'medium' }}
        background={'light-2'}
        round
      >
        <Text>{promptText}</Text>
        <Box gap="smaxll" direction="row" justify="between">
          <SHDButton
            alignSelf="end"
            secondary
            color="status-critical"
            label={<Text color="status-critical">Delete permanently</Text>}
            onClick={onDelete}
          />
          <Box direction="row" gap="small">
            <SHDButton
              secondary
              label="Deactivate instead"
              onClick={onDeactivate}
            />
            <SHDButton
              label="Cancel"
              onClick={() => {
                setShowRemovePrompt(false);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Layer
      modal
      full="vertical"
      style={{ height: '100dvh' }}
      animation={false}
      onClick={(e) => e.stopPropagation()}
      onEsc={onClose}
    >
      <Card round={false} elevation="none" fill>
        <CardHeader
          direction="row"
          align={'center'}
          justify={'between'}
          pad={'large'}
        >
          <Box alignSelf="start" onClick={onClose}>
            <LinkPrevious size="24px" />
          </Box>
          <Text weight="bold" alignSelf="center">
            {create ? 'Create Player' : 'Edit Player'}
          </Text>
          <Box alignSelf="end" width={'24px'} />
        </CardHeader>
        <CardBody
          width={screenSize === 'small' ? '100%' : { min: 'large' }}
          overflow={'auto'}
        >
          {error && <Text color="status-critical">{error}</Text>}
          {teamPlayer?.claimedPlayerId && (
            <Box
              overflow={'hidden'}
              background={'gray'}
              width={'100%'}
              height={'88px'}
            />
          )}
          {teamPlayer?.claimedPlayerId && (
            <Box
              fill="horizontal"
              direction="row"
              gap="medium"
              pad="medium"
              justify="between"
            >
              <Box
                round="full"
                border={{
                  side: 'all',
                  color: 'white',
                  size: '2px',
                }}
                height={screenSize === 'small' ? '80px' : '120px'}
                width={screenSize === 'small' ? '80px' : '120px'}
                overflow={'hidden'}
                flex={{ shrink: 0 }}
                background={'light-1'}
                margin={{ top: `-48px` }}
              >
                {imageBlob || claimedPlayer?.DNOR_imgUrlT150 ? (
                  <Image
                    fit="contain"
                    src={
                      imageBlob
                        ? URL.createObjectURL(imageBlob)
                        : claimedPlayer?.DNOR_imgUrlT150 || ''
                    }
                    onLoad={() => {
                      if (imageBlob) {
                        URL.revokeObjectURL(URL.createObjectURL(imageBlob));
                      }
                    }}
                  />
                ) : (
                  <Avatar size="full" background="gray-2" />
                )}
              </Box>
              <Box direction="row" gap="medium" fill="horizontal" justify="end">
                <Box justify="start" gap="small">
                  <Button
                    size="small"
                    secondary
                    label="Change Image"
                    onClick={() => {
                      document.getElementById('imageInput')?.click();
                    }}
                  />
                  <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                    style={{ display: 'none' }}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box pad="medium">
            <Form
              validate="blur"
              value={teamPlayer}
              onChange={(nextPlayer) => {
                setTeamPlayer(nextPlayer);
                setIsValid(true);
              }}
              onReset={() => setTeamPlayer(initialTeamPlayer)}
              onSubmit={(e) => {
                e.preventDefault();
                onSave();
              }}
              onValidate={(validationResults) => {
                const jerseyNumValid =
                  validationResults.errors.jerseyNum === undefined;
                const nameFirstValid =
                  validationResults.errors.nameFirst === undefined;
                const nameLastValid =
                  validationResults.errors.nameLast === undefined;
                setIsValid(jerseyNumValid && nameFirstValid && nameLastValid);
              }}
            >
              <Box
                onClick={
                  editingDisabled
                    ? () => {
                        setEditingDisabledToast({
                          label: 'This player has been claimed.',
                          background: 'status-warning',
                        });
                      }
                    : () => {}
                }
                focusIndicator={false}
              >
                <FieldNameFirst disabled={editingDisabled} />
                <FieldNameLast required={false} disabled={editingDisabled} />
              </Box>
              <FieldJerseyNum />
              {!editingDisabled ? (
                <Box margin={{ vertical: 'medium' }} gap="small">
                  <Text size="xsmall" weight="bold" color="black">
                    Phone Numbers
                  </Text>
                  <TagInput
                    disabled={editingDisabled}
                    value={teamPlayer?.invitePhoneNumberList}
                    onTagAdded={onPhoneNumberChanged}
                    onTagRemoved={onPhoneNumberChanged}
                  />
                  <Text size="small" color="dark-3">
                    Invite a parent/player to receive texted highlights and
                    complete the player profile
                  </Text>
                </Box>
              ) : null}
              {!editingDisabled && !create && teamPlayer && (
                <Box>
                  <Box
                    height={'2px'}
                    fill="horizontal"
                    background={'light-2'}
                  />
                  {(!isTeamAdmin || !isBBSB) && (
                    <>
                      <FormField label="Player name display style">
                        <Select
                          disabled={editingDisabled}
                          placeholder="Select"
                          labelKey="label"
                          value={getNameStyleValue(teamPlayer)}
                          valueKey={{ key: 'value', reduce: true }}
                          options={[
                            { label: 'Willie Mays', value: 40 },
                            { label: 'Willie M.', value: 30 },
                            { label: 'W. Mays', value: 20 },
                            { label: 'W.M.', value: 10 },
                            { label: "Don't display", value: 0 },
                          ].filter((option) => {
                            if (
                              !userStore.selectedTeam?.isAge13Plus &&
                              option.value === 40
                            ) {
                              return false;
                            }
                            if (
                              !userStore.selectedTeam?.isAge13Plus &&
                              option.value === 20
                            ) {
                              return false;
                            }
                            return true;
                          })}
                          onChange={({ option }) => {
                            setTeamPlayer((prevPlayer) => {
                              if (!prevPlayer) {
                                return prevPlayer;
                              }
                              const newPlayer = { ...prevPlayer };
                              if (option.value === 40) {
                                newPlayer.embedNameFirstLevel = 2;
                                newPlayer.embedNameLastLevel = 2;
                              } else if (option.value === 30) {
                                newPlayer.embedNameFirstLevel = 2;
                                newPlayer.embedNameLastLevel = 1;
                              } else if (option.value === 20) {
                                newPlayer.embedNameFirstLevel = 1;
                                newPlayer.embedNameLastLevel = 2;
                              } else if (option.value === 10) {
                                newPlayer.embedNameFirstLevel = 1;
                                newPlayer.embedNameLastLevel = 1;
                              } else if (option.value === 0) {
                                newPlayer.embedNameFirstLevel = 0;
                                newPlayer.embedNameLastLevel = 0;
                                newPlayer.isNameOnPublicWebsite = false;
                              }
                              return newPlayer;
                            });
                          }}
                        />
                      </FormField>
                      {(teamPlayer.embedNameFirstLevel ||
                        teamPlayer.embedNameLastLevel) && (
                        <FormField label="Show player names on team page">
                          <Select
                            disabled={editingDisabled}
                            placeholder="Select"
                            labelKey="label"
                            value={
                              teamPlayer.isNameOnPublicWebsite
                                ? { label: 'Yes', value: true }
                                : { label: 'No', value: false }
                            }
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                            onChange={({ option }) => {
                              teamPlayer.isNameOnPublicWebsite = option.value;
                              setTeamPlayer({ ...teamPlayer });
                            }}
                          />
                        </FormField>
                      )}
                      <FormField
                        label={'Show player profile pictures on team page'}
                        disabled={editingDisabled}
                      >
                        <Select
                          placeholder="Select"
                          labelKey="label"
                          value={
                            teamPlayer.isEmbedImagePublicWebsite
                              ? { label: 'Yes', value: true }
                              : { label: 'No', value: false }
                          }
                          options={[
                            { label: 'Yes', value: true },
                            { label: 'No', value: false },
                          ]}
                          onChange={({ option }) => {
                            teamPlayer.isNameOnPublicWebsite = option.value;
                            setTeamPlayer({ ...teamPlayer });
                          }}
                          disabled={editingDisabled}
                        />
                      </FormField>
                      <FormField
                        label={`Show this (unclaimed) player's clips to public
                (recommended)`}
                      >
                        <Select
                          placeholder="Select"
                          labelKey="label"
                          value={teamPlayer.publicPublishLevel}
                          valueKey={{ key: 'value', reduce: true }}
                          options={[
                            { label: 'Yes', value: 10 },
                            { label: 'No', value: 5 },
                          ]}
                          onChange={({ option }) => {
                            teamPlayer.publicPublishLevel = option.value;
                            setTeamPlayer({ ...teamPlayer });
                          }}
                          disabled={editingDisabled}
                        />
                      </FormField>
                    </>
                  )}
                  {showRemovePrompt ? (
                    removePrompt()
                  ) : (
                    <Box margin={{ top: 'large', bottom: '64px' }} gap="medium">
                      <Box direction="row" gap="medium">
                        <Button
                          secondary
                          color="status-critical"
                          label={
                            <Text color="status-critical">Deactivate</Text>
                          }
                          onClick={onDeactivate}
                        />
                        {teamPlayer?.claimedPlayerId === '' && (
                          <Button
                            secondary
                            disabled={showRemovePrompt}
                            color="status-critical"
                            label={
                              <Text weight="bold" color="status-critical">
                                Delete
                              </Text>
                            }
                            onClick={onDeletePrompt}
                          />
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              <button
                style={{ display: 'none' }}
                type="submit"
                ref={submitButtonRef}
              />
            </Form>
            {!create && teamPlayer?.claimedPlayerId !== '' && (
              <Button
                secondary
                color="status-critical"
                label={<Text color="status-critical">Deactivate</Text>}
                onClick={onDeactivate}
              />
            )}
          </Box>
        </CardBody>
        <CardFooter
          pad={{ horizontal: 'small', vertical: 'large' }}
          background="light-2"
        >
          <Button
            fill
            primary
            label={loading ? '...Saving' : 'Save'}
            onClick={() => {
              onSave();
            }}
            type="submit"
            size="large"
            disabled={!isValid}
          />
        </CardFooter>
      </Card>
      {showImageCropper && src && (
        <ImagePickerCropper
          src={src}
          circularCrop
          aspect={1}
          onClose={() => {
            setShowImageCropper(false);
          }}
          onSave={onSelectedImage}
        />
      )}
      {editingDisabledToast && (
        <Toast
          label={editingDisabledToast.label}
          background={editingDisabledToast.background}
          duration={3000}
          onClose={() => {
            setEditingDisabledToast(undefined);
          }}
        />
      )}
    </Layer>
  );
};

export default EditPlayerLayer;
