// Enums
export enum Terms {
  ANNUAL = 'annual',
  ANNUAL_SHORT = 'yr',
  MONTHLY = 'monthly',
  MONTHLY_SHORT = 'mo',
}

export enum PackageName {
  PRO = 'Pro Access',
  PRO_MONTHLY = 'Pro Access Monthly',
  PRO_ANNUAL = 'Pro Access Annual',
}

// Types
export type SubscriptionTerms = Terms.MONTHLY | Terms.ANNUAL;
export type PaywallCtaModes =
  | 'phone'
  | 'fullAccess'
  | 'playerSettings'
  | 'default';
export type PaywallLockModes =
  | 'default'
  | 'hitscope'
  | 'game'
  | 'gameHitscope'
  | 'teamPlayer'
  | 'download';
// Intefaces
export interface ActiveSubscription {
  productIdentifier: string;
  productName: string;
  productPrice: string;
  productTerm: {
    short: string;
    long: string;
  };
}

export interface PaywallCtaProps {
  context: PaywallContext;
  mode: PaywallCtaModes;
  playerId?: string;
}

export interface PaywallFeatureListItem {
  title: string;
  copy: string;
}

export interface PaywallFeatureListProps {
  iconColor?: string;
}

export interface PaywallLockProps {
  isClaimed?: boolean;
  isLoggedIn?: boolean;
  isPrivate: boolean;
  mode?: PaywallLockModes;
}

export interface PaywallProps {
  context: PaywallContext;
  isOpen: boolean;
  playerId?: string;
  onClose: () => void;
  onUnlockAccess: (offering: SubscriptionOffering) => void;
}

export interface SubscriptionOffering {
  package: string;
  price: string | null;
  term: SubscriptionTerms;
}

export enum ViewerEntitlementStatus {
  ENTITLED = 'ENTITLED',
  ENTITLED_BUT_PRIVATE = 'ENTITLED_BUT_PRIVATE',
  NOT_ENTITLED_LOGGED_IN = 'NOT_ENTITLED_LOGGED_IN',
  NOT_ENTITLED_LOGGED_OUT = 'NOT_ENTITLED_LOGGED_OUT',
}

export enum PaywallContext {
  ACCOUNT_SETTINGS = 'account_settings',
  GAME_HITSCOPE = 'game_hitscope',
  GAME_PAGE = 'game_page',
  PLAYER_HITSCOPE = 'player_hitscope',
  PLAYER_PAGE = 'player_page',
  PLAYER_SETTINGS = 'player_settings',
  PLAYLIST_PAGE = 'playlist',
  REELS_PAGE = 'reels',
  SINGLE_CLIP_PAGE = 'single_clip_page',
  TEAM_HITSCOPE = 'team_hitscope',
  VIEW_BUTTON = 'view_button',
}

export enum PaywallEvents {
  CANCEL_MEMBERSHIP_CLICKED = 'Cancel Membership Clicked',
  GAME_PAGE_VIEW_BUTTON_CLICKED = 'Game Page View Button Clicked',
  OFFER_PAGE_DISMISSED = 'Offer Page Dismissed',
  OFFER_PAGE_VIEWED = 'Offer Page Viewed',
  OFFERING_SELECTED = 'Offering Selected',
  PAYWALL_DISPLAYED = 'Paywall Displayed',
  UNLOCK_ACCESS_CLICKED = 'Unlock Access Clicked',
  VIEW_MEMBERSHIP_DETAILS_CLICKED = 'View Membership Details Clicked',
  VIEW_PLANS_CLICKED = 'View Plans Clicked',
}
