// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Box, Button, Heading, Image, Layer, Text } from 'grommet';

import React, { useEffect } from 'react';
import { analytics } from '../../services/analytics';

const ClaimWall = ({ teamPlayer, claimReason, ...props }) => {
  const trackingProperties = {
    claimReason,
    // eslint-disable-next-line no-underscore-dangle
    playerId: teamPlayer._id,
  };
  useEffect(() => {
    analytics.track('Claim Wall Claim View', trackingProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layer>
      <Box pad="medium" gap="medium">
        <Heading level={3} fill>
          {claimReason}
        </Heading>
        <Box gap="small">
          <Text size="large" weight="bold">
            Why claim my player?
          </Text>
          <Image src="/claim_preview.png" width="100%" />
          <Box gap="medium">
            <Text>✅ Profile shows up in your teams&apos; livestreams</Text>
            <Text>✅ Fully customize your player&apos; profile</Text>
            <Text>✅ Get exclusive access to new features</Text>
          </Box>
        </Box>
        <Button
          label="Claim Now"
          primary
          color="tertiary-1"
          size="large"
          onClick={() => {
            analytics.track('Claim Wall Claim Now Clicked', trackingProperties);
            props.onShowClaim();
          }}
        />
        <Button
          label={<Text color="status-critical">Maybe Later</Text>}
          secondary
          color="white"
          onClick={() => {
            props.onClose();
          }}
        />
      </Box>
    </Layer>
  );
};

export default ClaimWall;
