// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { observer } from 'mobx-react';
import { Box, Card, CardBody, CardHeader, Heading, Image, Text } from 'grommet';

import { UserContext } from '../../services/Session';
import * as ROUTES from '../../constants/routes';
import Waiting from '../ClaimPlayerPage/Waiting';
import { analytics } from '../../services/analytics';

const OnboardingIntent = observer(
  withRouter((props) => {
    const userStore = useContext(UserContext);
    return (
      <Box pad="medium" gap="large" width={{ max: 'large' }}>
        <Waiting />
        <Heading level={3} margin={{ bottom: 'none' }}>
          I want to...
        </Heading>
        <Card
          elevation="none"
          border
          round
          onClick={() => {
            window.sendinblue.identify(userStore.authUser.claims.email);
            window.sendinblue.track('livestreamer_signup', {
              importSource: 'None',
            });

            // slightly awkward event name, but want to stay consistent with
            // the historical event name from the old onboarding flow
            analytics.track('Livestreamer Sign Up', {
              importSource: 'None',
            });
            props.afterValidation();
          }}
        >
          <CardHeader>
            <Image src="livestream_header.png" fit="contain" />
          </CardHeader>
          <CardBody pad="medium" gap="medium">
            <Text weight="bold">Livestream</Text>
            <Text color="dark-3">
              Become your teams&apos; livestreaming hero with your smartphone,
              Mevo, or GoPro
            </Text>
          </CardBody>
        </Card>
        <Card
          elevation="none"
          border
          round
          onClick={() => {
            analytics.track('Claim my player Clicked', {});
            props.history.push(ROUTES.CLAIM_PLAYER_HOME);
          }}
        >
          <CardHeader>
            <Image src="claim_preview.png" fit="contain" />
          </CardHeader>
          <CardBody pad="medium" gap="medium">
            <Text weight="bold">Claim my player</Text>
            <Text color="dark-3">
              My child is already on a team that uses sidelineHD for
              livestreaming. I want to claim them so I can manage their player
              profile and receive post-game team highlights, box-scores, and
              recaps.
            </Text>
          </CardBody>
        </Card>
      </Box>
    );
  })
);

export default OnboardingIntent;
