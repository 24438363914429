// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
import {
  Anchor,
  Avatar,
  Box,
  CardHeader,
  Form,
  Heading,
  Layer,
  Select,
  Tab,
  Tabs,
  Text,
  TextInput,
} from 'grommet';
import { Close, User } from 'grommet-icons';
import React, { useContext, useRef, useState } from 'react';
import Compressor from 'compressorjs';
import { observer } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import FileUpload from '../../helpers/FileUpload';
import { UserContext } from '../../services/Session';
import SHDButton from '../../components/SHD/Button';
import axios from '../../helpers/axios';
import {
  FieldCollege,
  FieldCommitStatus,
  FieldGPA,
  FieldGradYear,
  FieldBBSBHandedness,
  FieldNameFirst,
  FieldNameHandle,
  FieldNameLast,
  FieldNameLocation,
  FieldPlayerPosition,
  FieldWeight,
  FieldHandedness,
  FieldSocialHandle,
  FieldHero,
  FieldBat,
  FieldMLBTeam,
} from '../../helpers/FormFields';
import TagInput from '../../helpers/TagInput';
import PlayerSettings from './PlayerSettings';
import {
  BASEBALL,
  BASKETBALL,
  SOCCER,
  SOFTBALL,
  SPORTS_MAP,
  VOLLEYBALL,
} from '../../constants/strings';
import { useIsFeatureEnabled } from '../../helpers/utils';
import PlayerIntegrations from './PlayerIntegrations';

import PaywallCta from '../../components/Paywall/PaywallCta';
import { BasicLoader } from '../../helpers/Loaders';
import EditPlayerTooltipContent from './EditPlayerTooltipContent';
import HelpTooltip from '../../helpers/HelpTooltip';
import { PaywallContext } from '../../components/Paywall/paywall.types';
import { useCanEditMonetizedPlayerFields } from './playerHooks';

const EditPlayerProfile = observer(
  ({
    claimedPlayer = {},
    closeLayer = () => {},
    setShowEditSuccess = () => {},
    teams,
  }) => {
    const userStore = useContext(UserContext);
    const footerRef = useRef();
    const [error, setError] = useState();
    const [value, setValue] = useState({
      ...claimedPlayer,
    });
    const [image, setImage] = useState(claimedPlayer.DNOR_imgUrlT150);
    const [loading, toggleLoading] = useState(false);
    const showIntegrations = useIsFeatureEnabled(
      'show_integrations',
      userStore
    );

    // Paywall
    const isMonetizationEnabled = useIsFeatureEnabled(
      'monetization',
      userStore
    );

    const getTeamOptions = () => {
      const teamsMap = Object.fromEntries(
        teams.map((team) => [team._id, team])
      );

      const options = value.DNOR_teamIdPriority.map((teamId) => ({
        label: teamsMap[teamId].nameLong,
        value: teamId,
        sportType: teamsMap[teamId].attrib_sportType,
      }));
      return options;
    };

    const teamOptions = getTeamOptions();
    const playerSports = new Set(teamOptions.map((t) => t.sportType));

    const isBBSB = teamOptions.some(
      (team) => team.sportType === 'Baseball' || team.sportType === 'Softball'
    );

    const { canEditMonetizedFields, loading: viewerEntitlementLoading } =
      useCanEditMonetizedPlayerFields(
        claimedPlayer._id,
        userStore.authUser?.claims?.shd_user_id,
        isBBSB ? 'bbsb' : undefined
      );

    const onPhoneNumberChanged = (phoneList) => {
      setValue((prevValue) => {
        const newValue = { ...prevValue };
        newValue.notifyGuestPhoneNumbers = phoneList;
        return newValue;
      });
    };

    const handleFile = (imageFile, imageError) => {
      if (imageError) {
        setError(imageError);
        window.scrollTo(0, 0);
        return false;
      }
      setError(null);
      setImage(URL.createObjectURL(imageFile));

      setValue((prevState) => {
        const newState = { ...prevState };
        newState.image = imageFile;
        return newState;
      });
      return true;
    };

    const saveEdit = () => {
      toggleLoading(true);
      let imagePromise;
      if (value.image) {
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            responseType: 'arraybuffer',
          },
        };
        imagePromise = new Promise((resolve, reject) => {
          // eslint-disable-next-line no-new
          new Compressor(value.image, {
            maxWidth: 1920,

            success(result) {
              const formData = new FormData();

              // The third parameter is required for server
              formData.append('image', result, result.name);

              axios
                .post(`/api/player/${value._id}/image`, formData, config)
                .then((response) => {
                  resolve(response.data);
                })
                .catch(reject);
              return true;
            },
            error(err) {
              setError(err.message);
            },
          });
        });
      } else {
        imagePromise = new Promise((resolve) => {
          resolve();
        });
      }

      const editPlayerPromise = new Promise((resolve, reject) => {
        const newValue = { ...value };
        delete newValue.image;

        // fix some keys
        for (const key in newValue) {
          if (key.includes('-hero')) {
            const sport = key.split('-hero')[0];
            if ('playerHero' in newValue === false) {
              newValue.playerHero = {};
            }

            newValue.playerHero[sport] = newValue[key];

            delete newValue[key];
          }

          if (key === 'bat') {
            if ('equipment' in newValue === false) {
              newValue.equipment = {};
            }

            newValue.equipment.bat = newValue[key];
            delete newValue[key];
          }

          if (key === 'favoriteMLBTeam') {
            if ('favoriteTeams' in newValue === false) {
              newValue.favoriteTeams = {};
            }

            newValue.favoriteTeams.baseball = newValue[key];
            delete newValue[key];
          }
        }

        axios
          .put(`/api/claimed_player/${newValue._id}`, { playerInfo: newValue })
          .then((response) => {
            resolve(response.data);
          })
          .catch(reject);
      });

      Promise.all([imagePromise, editPlayerPromise])
        .then((responses) => {
          const [newImage, newSelectedPlayer] = responses;
          if (newImage) {
            setImage(newImage);
            newSelectedPlayer.DNOR_imgUrlT150 = newImage;
          }
          // TODO: only grab useful fields for selected player
          userStore.setSelectedPlayer(newSelectedPlayer);
          const players = [...userStore.userPlayers].map((storePlayer) => {
            if (storePlayer._id === value._id) {
              return newSelectedPlayer;
            }
            return storePlayer;
          });
          userStore.setUserPlayers(players);
          setError(null);
          setShowEditSuccess({
            ...value,
            DNOR_imgUrlT150: newImage || '',
          });
          toggleLoading(false);
          closeLayer();
        })
        .catch((respError) => {
          if (respError.response) {
            setError(respError.response.data.message);
          } else {
            setError('Something went wrong. Please refresh and try again.');
          }
          toggleLoading(false);
          document.getElementById('edit-player-box').scrollTo(0, 0);
          Sentry.captureException(respError);
        });
    };

    const renderPositions = () => {
      const els = [];

      // TODO: refactor to do this in a loop?
      if (playerSports.has(BASEBALL.display)) {
        els.push(
          <>
            <FieldPlayerPosition isPrimary sportType={BASEBALL.display} />
            <FieldPlayerPosition
              isPrimary={false}
              sportType={BASEBALL.display}
            />
          </>
        );
      } else if (playerSports.has(SOFTBALL.display)) {
        els.push(
          <>
            <FieldPlayerPosition isPrimary sportType={SOFTBALL.display} />
            <FieldPlayerPosition
              isPrimary={false}
              sportType={SOFTBALL.display}
            />
          </>
        );
      }

      if (playerSports.has(VOLLEYBALL.display)) {
        els.push(
          <>
            <FieldPlayerPosition isPrimary sportType={VOLLEYBALL.display} />
            <FieldPlayerPosition
              isPrimary={false}
              sportType={VOLLEYBALL.display}
            />
          </>
        );
      }

      if (playerSports.has(BASKETBALL.display)) {
        els.push(
          <>
            <FieldPlayerPosition isPrimary sportType={BASKETBALL.display} />
            <FieldPlayerPosition
              isPrimary={false}
              sportType={BASKETBALL.display}
            />
          </>
        );
      }

      if (playerSports.has(SOCCER.display)) {
        els.push(
          <>
            <FieldPlayerPosition isPrimary sportType={SOCCER.display} />
            <FieldPlayerPosition isPrimary={false} sportType={SOCCER.display} />
          </>
        );
      }

      return els;
    };

    const renderPlayerProfile = () => {
      const primaryTeam = value.DNOR_teamIdPriority[0];

      return (
        <Box
          fill="horizontal"
          height="100%"
          flex
          id="edit-player-box"
          pad="medium"
          margin={{
            top: 'medium',
          }}
          gap="medium"
          overflow="auto"
        >
          {error && <Text color="status-critical">{error}</Text>}
          <Box
            direction="row"
            align="center"
            gap="small"
            justify="start"
            height={{ min: 'small' }}
          >
            {image ? (
              <Avatar src={image} size="large" />
            ) : (
              <Avatar background="dark-1" size="large">
                <User color="white" size="large" />
              </Avatar>
            )}
            <FileUpload handleFile={handleFile}>
              <Anchor label={`${image ? 'Change' : 'Add'} profile picture`} />
            </FileUpload>
          </Box>
          <Form
            value={value}
            onChange={(nextValue) => {
              setValue(nextValue);
            }}
            onReset={() => setValue({ ...userStore.selectedPlayer })}
            onSubmit={saveEdit}
          >
            <Box flex pad={{ bottom: '108px' }}>
              <FieldNameFirst />
              <FieldNameLast required={false} />
              <FieldNameHandle label="Handle" placeholder="mike-trout27" />
              <Box pad="small" gap="small">
                <Text>Primary team</Text>
                <Select
                  placeholder="Select"
                  labelKey="label"
                  value={primaryTeam}
                  valueKey={{ key: 'value', reduce: true }}
                  options={getTeamOptions()}
                  onChange={({ option }) => {
                    setValue((prevValue) => {
                      const newValue = { ...prevValue };
                      const newTeamIdPriority = [option.value].concat(
                        newValue.DNOR_teamIdPriority.filter(
                          (teamId) => teamId !== option.value
                        )
                      );
                      newValue.DNOR_teamIdPriority = newTeamIdPriority;
                      return newValue;
                    });
                  }}
                >
                  {(option, state) => (
                    <Box
                      direction="row"
                      pad="small"
                      gap="xsmall"
                      background={state.active ? 'active' : undefined}
                      align="center"
                    >
                      {SPORTS_MAP[option.sportType].icon}
                      {option.sportType}
                      {option.label}
                    </Box>
                  )}
                </Select>
              </Box>

              {isMonetizationEnabled && (
                <>
                  {canEditMonetizedFields && !viewerEntitlementLoading ? (
                    <Box>
                      <Box
                        pad="small"
                        direction="row"
                        gap="small"
                        align="center"
                      >
                        <Text>Additional numbers to notify (max 5)</Text>
                        <HelpTooltip
                          content={
                            <EditPlayerTooltipContent
                              isMonetizationEnabled={isMonetizationEnabled}
                            />
                          }
                        />
                      </Box>
                      <TagInput
                        value={value.notifyGuestPhoneNumbers}
                        onTagAdded={onPhoneNumberChanged}
                        onTagRemoved={onPhoneNumberChanged}
                        limit={5}
                      />
                    </Box>
                  ) : (
                    <Box margin={{ top: '1.5rem' }}>
                      {viewerEntitlementLoading ? (
                        <BasicLoader />
                      ) : (
                        <PaywallCta
                          mode="phone"
                          context={PaywallContext.PLAYER_SETTINGS}
                          playerId={value._id}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
              {!isMonetizationEnabled && (
                <Box>
                  <Box pad="small" direction="row" gap="small" align="center">
                    <Text>Additional numbers to notify (max 5)</Text>
                    <HelpTooltip
                      content={
                        <EditPlayerTooltipContent
                          isMonetizationEnabled={isMonetizationEnabled}
                        />
                      }
                    />
                  </Box>
                  <TagInput
                    value={value.notifyGuestPhoneNumbers}
                    onTagAdded={onPhoneNumberChanged}
                    onTagRemoved={onPhoneNumberChanged}
                    limit={5}
                  />
                </Box>
              )}

              <Box margin={{ top: 'large' }}>
                <Heading level={5}>Player info</Heading>
                <FieldNameLocation isTeam={false} />
                {Array.from(playerSports).map((sport) => (
                  // eslint-disable-next-line react/jsx-key
                  <FieldHero
                    sportType={SPORTS_MAP[sport].key}
                    sportDisplay={SPORTS_MAP[sport].display}
                    player={value}
                  />
                ))}
                {playerSports.has(BASEBALL.display) && (
                  <FieldMLBTeam player={value} />
                )}
                {(playerSports.has(BASEBALL.display) ||
                  playerSports.has(SOFTBALL.display)) && (
                  <FieldBat player={value} />
                )}
                <FieldGradYear />
                {renderPositions()}
                {(playerSports.has(BASEBALL.display) ||
                  playerSports.has(SOFTBALL.display)) && (
                  <>
                    <FieldBBSBHandedness isBatting />
                    <FieldBBSBHandedness isBatting={false} />
                  </>
                )}
                {playerSports.has(VOLLEYBALL.display) && <FieldHandedness />}
                {/* <FieldHeight player={value} /> */}
                <Box gap="xsmall" margin={{ vertical: 'xsmall' }}>
                  <Text size="large" color="dark-3">
                    Height
                  </Text>
                  <Box direction="row" gap="xsmall" align="center">
                    <TextInput
                      autoComplete="nope"
                      id="height-feet-id"
                      placeholder="5"
                      onChange={(e) => {
                        setValue((prevValue) => {
                          let heightInches = '0';
                          if (prevValue.height.split("'").length > 1) {
                            heightInches = prevValue.height
                              .split("'")[1]
                              .replace('"', '');
                          }

                          const newValue = { ...prevValue };
                          let newHeight = '';
                          if (heightInches) {
                            newHeight = `${e.target.value}'${heightInches}"`;
                          }

                          newValue.height = newHeight;
                          return newValue;
                        });
                      }}
                      value={value.height ? value.height.split("'")[0] : ''}
                      plain
                    />
                    <Text color="text-weak">ft</Text>
                    <TextInput
                      autoComplete="nope"
                      id="height-inches-id"
                      placeholder="5"
                      onChange={(e) => {
                        setValue((prevValue) => {
                          let heightFeet = '5';
                          if (prevValue.height.split("'").length > 1) {
                            [heightFeet] = prevValue.height.split("'");
                          }

                          const newValue = { ...prevValue };
                          let newHeight = '';
                          if (heightFeet) {
                            newHeight = `${heightFeet}'${e.target.value}"`;
                          }

                          newValue.height = newHeight;
                          return newValue;
                        });
                      }}
                      value={
                        value.height
                          ? value.height.split("'")[1].replace('"', '')
                          : ''
                      }
                      plain
                    />
                    <Text color="text-weak">in</Text>
                  </Box>
                </Box>
                <FieldWeight />
                <FieldCommitStatus />
                {value.collegeCommitStatus === 10 ? <FieldCollege /> : null}
                <FieldGPA />
              </Box>
              <Box margin={{ top: 'large' }}>
                <Heading level={5} margin={{ left: 'small' }}>
                  Social media
                </Heading>
                <Text>
                  We post great plays on social! To be tagged, enter your info.
                </Text>
                <FieldSocialHandle socialType="twitter" />
                <FieldSocialHandle socialType="tiktok" />
                <FieldSocialHandle socialType="instagram" />
              </Box>
            </Box>
            <Box
              ref={(el) => {
                footerRef.current = el;
              }}
              fill="horizontal"
              style={{
                position: 'fixed',
                left: 0,
                bottom: 0,
                width: '100%',
                zIndex: 10,
              }}
              pad={{
                horizontal: 'small',
                vertical: '22px',
              }}
              background="light-3"
            >
              <SHDButton
                primary
                size="large"
                type="submit"
                label={loading ? '...Saving' : 'Save'}
                disabled={
                  JSON.stringify({ ...claimedPlayer }) ===
                    JSON.stringify(value) || loading
                }
                color="tertiary-1"
              />
            </Box>
          </Form>
        </Box>
      );
    };

    return (
      <Layer
        id="edit-player-outer-layer"
        modal
        full="vertical"
        style={{ height: '100dvh' }}
        animation={false}
      >
        <CardHeader>
          <Box width="24px" />
          <Box justify="center" align="center">
            <Text alignSelf="center" size="large" weight="bold">
              Edit Player
            </Text>
          </Box>
          <SHDButton icon={<Close />} onClick={closeLayer} justify="end" />
        </CardHeader>
        <Tabs>
          <Tab id="profile-tab" title="Profile">
            {renderPlayerProfile()}
          </Tab>
          <Tab title="Settings">
            <PlayerSettings
              closeLayer={closeLayer}
              setShowEditSuccess={setShowEditSuccess}
              claimedPlayer={claimedPlayer}
              isBBSB={isBBSB}
            />
          </Tab>
          {showIntegrations && (
            <Tab title="Integrations">
              <PlayerIntegrations
                closeLayer={closeLayer}
                setShowEditSuccess={setShowEditSuccess}
                claimedPlayer={claimedPlayer}
              />
            </Tab>
          )}
        </Tabs>
      </Layer>
    );
  }
);

export default EditPlayerProfile;
