import axios from '../../../src/helpers/axios';
import { strogging } from '@shd/jslib/infra';
import { theFirebase } from '../../services/Firebase/firebase';
import * as ROUTES from '../../constants/routes';

export const useSettingsHooks = () => {
  const deleteUserAccount = async (userId: string | null) => {
    if (!userId) {
      throw new Error('User ID is required');
    }

    try {
      // NOTE: Commented out for safety. (NKS: 2024-07-27)
      axios.delete(`/api/user/${userId}`).then(async (response) => {
        strogging.log(`Deleting account for user with ID: ${userId}`);
        if (response.status === 200) {
          strogging.log(`Account deleted for user with ID: ${userId}`);
          await theFirebase()
            .doSignOut()
            .then(() => {
              window.location.href = ROUTES.SIGN_IN;
            });
        }
      });
    } catch (error) {
      strogging.log(`Error deleting account for user with ID: ${userId}`);
    }
  };

  const updateEmail = async (email: string, userId?: string) => {
    return axios
      .put(`/api/user/${userId}`, {
        update_email: { email: email },
      })
      .then((response) => response)
      .catch((error) => {
        strogging.log(`Error updating email ${email} for user ${userId}`);
        throw new Error(error);
      });
  };
  return { deleteUserAccount, updateEmail };
};

export default useSettingsHooks;
