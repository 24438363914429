import { shd } from '@shd/jslib/models';
import { Box, CheckBox, Grid, ResponsiveContext, Text } from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../services/Session';
import axios from '../../helpers/axios';
import PlayerCard from './PlayerCard';
import RolloverPlayerLayer from '../../components/RolloverPlayerLayer';
import EditPlayerLayer from '../Player/EditPlayerLayer';
import { compareJerseyNum } from '../../helpers/utils';

import { usePermitClaimPlayersOnTeam } from '../../hooks/usePlayerClaims';

import AddPlayer from './AddPlayer';

interface Props {
  team: shd.Team;
  isAdmin: boolean;
}

const Roster: React.FC<Props> = ({ team, isAdmin }) => {
  const screenSize = useContext(ResponsiveContext);
  const userStore = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [teamPlayers, setTeamPlayers] = useState<shd.TeamPlayer[]>([]);
  const [claimedPlayers, setClaimedPlayers] = useState<shd.ClaimedPlayer[]>([]);
  const [claimedPlayerIdMap, setClaimedPlayerIdMap] = useState<
    Record<string, shd.ClaimedPlayer>
  >({});
  const [showDeactivatedPlayers, setShowDeactivatedPlayers] = useState(false);
  const [showRolloverPlayerModal, setShowRolloverPlayerModal] = useState(false);
  const [showCreatePlayerModal, setShowCreatePlayerModal] = useState(false);
  const [addPlayerOpen, setAddPlayerOpen] = useState<boolean>(false);
  const userTeams = userStore.userTeams.length;

  const loggedInUser = userStore.authUser?.claims?.shd_user_id;
  const canClaimPlayers = usePermitClaimPlayersOnTeam(team._id, loggedInUser);

  useEffect(() => {
    setClaimedPlayerIdMap(
      Object.assign({}, ...claimedPlayers.map((x) => ({ [x._id]: x })))
    );
  }, [claimedPlayers]);

  useEffect(() => {
    axios.get(`/api/team/${team._id}/players_details`).then((res) => {
      const {
        teamPlayers: respTeamPlayers,
        claimedPlayers: respClaimedPlayers,
      } = res.data;
      setTeamPlayers(respTeamPlayers);
      setClaimedPlayers(respClaimedPlayers);
      setLoading(false);
    });
  }, [team._id]);

  const renderEmptyState = () => {
    return (
      <Box gap="medium" color="transparent" pad="medium">
        <Text size="large" weight={'bold'}>
          {`${isAdmin ? 'Your' : 'The'} roster is currently empty`}
        </Text>
        {isAdmin && (
          <AddPlayer
            addPlayerOpen={addPlayerOpen}
            setAddPlayerOpen={setAddPlayerOpen}
            setShowCreatePlayerModal={setShowCreatePlayerModal}
            setShowRolloverPlayerModal={setShowRolloverPlayerModal}
            numberOfTeams={userTeams}
          />
        )}
      </Box>
    );
  };
  const renderPlayers = () => {
    if (loading) {
      return null;
    }
    if (teamPlayers.length === 0) {
      return renderEmptyState();
    }
    let cardColumns = '100%';
    if (screenSize === 'medium') {
      cardColumns = '1/2';
    } else if (screenSize === 'large') {
      cardColumns = '1/3';
    }

    return (
      <Grid columns={cardColumns} gap="large">
        {teamPlayers.sort(compareJerseyNum).map((teamPlayer) => {
          if (teamPlayer.isActive === false && !showDeactivatedPlayers) {
            return null;
          }
          const claimedPlayer = claimedPlayerIdMap[teamPlayer.claimedPlayerId];
          return (
            <PlayerCard
              key={teamPlayer._id}
              teamPlayer={teamPlayer}
              claimedPlayer={claimedPlayer}
              isAdmin={isAdmin}
              canClaimPlayers={canClaimPlayers}
            />
          );
        })}
      </Grid>
    );
  };

  return (
    <Box
      margin={{
        vertical: 'medium',
        horizontal: screenSize === 'small' ? 'medium' : 'none',
      }}
      gap="medium"
      skeleton={loading}
    >
      {teamPlayers.length > 0 && (
        <Box align="start" gap="medium">
          {isAdmin && (
            <AddPlayer
              addPlayerOpen={addPlayerOpen}
              setAddPlayerOpen={setAddPlayerOpen}
              setShowCreatePlayerModal={setShowCreatePlayerModal}
              setShowRolloverPlayerModal={setShowRolloverPlayerModal}
              numberOfTeams={userTeams}
            />
          )}
          {isAdmin && (
            <Box direction="row" justify="between" fill="horizontal">
              <Text size="small" weight={'bold'}>
                Show Deactivated Players
              </Text>
              <CheckBox
                onChange={(event) => {
                  setShowDeactivatedPlayers(event.target.checked);
                }}
              />
            </Box>
          )}
        </Box>
      )}
      {renderPlayers()}
      {showRolloverPlayerModal && (
        <RolloverPlayerLayer
          existingPlayerIds={teamPlayers.map((p) => p._id)}
          targetTeamId={team._id}
          onCloseModal={() => setShowRolloverPlayerModal(false)}
          onUpdatedPlayers={(newPlayers: shd.TeamPlayer[]) => {
            setTeamPlayers([...teamPlayers, ...newPlayers]);
          }}
        />
      )}
      {showCreatePlayerModal && (
        <EditPlayerLayer
          create
          onTeamPlayerUpdated={(newTeamPlayer) => {
            setTeamPlayers([...teamPlayers, newTeamPlayer]);
          }}
          onClose={() => setShowCreatePlayerModal(false)}
          isTeamAdmin={isAdmin}
        />
      )}
    </Box>
  );
};

export default Roster;
