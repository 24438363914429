import { shd } from '@shd/jslib/models';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Layer,
  Text,
} from 'grommet';
import React, { useContext, useEffect, useState } from 'react';
import { StatusGood, User } from 'grommet-icons';
import { formatPhoneNumber } from 'react-phone-number-input';
import EditPlayerLayer from '../Player/EditPlayerLayer';
import { getPrivacyName } from '../../helpers/utils';

import axios from '../../helpers/axios';
import { UserContext } from '../../services/Session';
import Toast from '../../components/Toast';
import * as Sentry from '@sentry/browser';
import SHDLink from '../../helpers/SHDLink';
import { analytics } from '../../services/analytics';

interface ReactivatePlayerModalProps {
  teamPlayer: shd.TeamPlayer;
  onClose: () => void;
  onUpdatedTeamPlayer: (teamPlayer: shd.TeamPlayer) => void;
}

const ReactivatePlayerModal: React.FC<ReactivatePlayerModalProps> = ({
  teamPlayer,
  onClose,
  onUpdatedTeamPlayer,
}) => {
  const [loading, setLoading] = useState(false);

  const handleReactivate = async () => {
    setLoading(true);
    const newPlayer = { ...teamPlayer, isActive: true };
    try {
      await axios.put(`/api/team_player/${teamPlayer._id}`, {
        player: newPlayer,
      });
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoading(false);
      onUpdatedTeamPlayer(newPlayer);
      onClose();
    }
  };

  return (
    <Layer
      modal
      full="vertical"
      style={{ height: '100dvh' }}
      animation={false}
      onClickOutside={onClose}
      onEsc={onClose}
    >
      <Card fill>
        <CardHeader pad="medium">
          <Text size="large" weight="bold">
            Reactivate Player
          </Text>
        </CardHeader>
        <CardBody pad="medium">
          <Text>
            Are you sure you want to reactivate {teamPlayer.nameFirst}?
          </Text>
        </CardBody>
        <CardFooter background="light-3">
          <Box direction="row" gap="small" pad="medium" fill>
            <Button
              primary
              label={loading ? 'Reactivating...' : 'Reactivate'}
              onClick={handleReactivate}
              disabled={loading}
            />
            <Button label="Cancel" onClick={onClose} color="dark-3" />
          </Box>
        </CardFooter>
      </Card>
    </Layer>
  );
};

interface ResendInviteModalProps {
  teamPlayer: shd.TeamPlayer;
  onClose: () => void;
}
const ResendInviteModal: React.FC<ResendInviteModalProps> = ({
  teamPlayer,
  onClose,
}) => {
  const userStore = useContext(UserContext);

  const [toast, setToast] = useState<shd.Toast>();

  const formattedPhoneNumbers = teamPlayer.invitePhoneNumberList
    .map((number) => formatPhoneNumber(number))
    .join(', ');

  return (
    <Layer
      modal
      full="vertical"
      style={{ height: '100dvh' }}
      animation={false}
      onClickOutside={onClose}
      onEsc={onClose}
    >
      <Card fill>
        <CardHeader pad="medium">
          <Text size="large" weight="bold">
            Resend Invite
          </Text>
        </CardHeader>
        <CardBody pad="medium">
          <Text>
            <Text weight="bold">{formattedPhoneNumbers}</Text>{' '}
            {'will receive an invite to claim'}{' '}
            <Text weight="bold">{teamPlayer.nameFirst}</Text>
          </Text>
        </CardBody>
        <CardFooter background="light-3">
          <Box direction="row" gap="small" pad="medium" fill>
            <Button
              primary
              color="tertiary-1"
              size="large"
              label="Resend Invite"
              onClick={() => {
                analytics.track('Resend Invite Clicked');

                axios
                  .post(
                    `/api/team/${userStore.selectedTeam?._id}/player/${teamPlayer._id}/send_claim_invite`
                  )
                  .then(() => {
                    setToast({
                      label: `Invite sent to claim ${teamPlayer.nameFirst}!`,
                      background: 'status-ok',
                    });
                  })
                  .catch(() => {
                    setToast({
                      label: 'Error resending invite',
                      background: 'status-error',
                    });
                  });

                onClose();
              }}
            />
            <Button
              size="large"
              color="status-critical"
              label="Cancel"
              onClick={onClose}
            />
          </Box>
        </CardFooter>
      </Card>
      {toast && <Toast {...toast} onClose={() => setToast(undefined)} />}
    </Layer>
  );
};

interface PlayerCardProps {
  teamPlayer: shd.TeamPlayer;
  claimedPlayer: shd.ClaimedPlayer | null;
  isAdmin: boolean;
  canClaimPlayers: boolean;
}

const PlayerCard: React.FC<PlayerCardProps> = ({
  teamPlayer: initialTeamPlayer,
  claimedPlayer: initialClaimedPlayer,
  isAdmin,
  canClaimPlayers,
}) => {
  const isClaimed = initialTeamPlayer.claimedPlayerId !== '';
  const isDKPaired = initialClaimedPlayer?.dkPlayerUuid;

  const [teamPlayer, setTeamPlayer] = useState(initialTeamPlayer);
  const [claimedPlayer, setClaimedPlayer] =
    React.useState(initialClaimedPlayer);

  const [showEdit, setShowEdit] = useState(false);
  const [showResendInviteModal, setShowResendInviteModal] =
    React.useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [displayClaimButton, setDisplayClaimButton] = useState(false);
  useEffect(() => {
    if (initialClaimedPlayer) {
      setClaimedPlayer(initialClaimedPlayer);
    }
  }, [initialClaimedPlayer]);

  useEffect(() => {
    setDisplayClaimButton(!isClaimed && canClaimPlayers);
  }, [isClaimed, canClaimPlayers]);

  const getSubtitle = () => {
    if (!teamPlayer.isActive) {
      return <Text size="small">Deactivated</Text>;
    }

    if (!isClaimed) {
      if (teamPlayer.invitePhoneNumberList.length) {
        const phoneNumberText = teamPlayer.invitePhoneNumberList
          .map((phoneNumber) => formatPhoneNumber(phoneNumber))
          .join(', ');
        return (
          <Box gap="xsmall">
            {isAdmin && (
              <Text size="small" truncate>
                {phoneNumberText}
              </Text>
            )}
            <Text size="small" color="status-critical">
              Unclaimed
            </Text>
          </Box>
        );
      }
      return (
        <Text size="small" color="status-critical">
          Unclaimed
        </Text>
      );
    }

    if (claimedPlayer?.positionPrimary) {
      return <Text>{claimedPlayer?.positionPrimary}</Text>;
    }
  };

  const getCTA = () => {
    if (!teamPlayer.isActive) {
      return (
        <Button
          primary
          label="Reactivate Player"
          onClick={(e) => {
            e.preventDefault();
            setShowReactivateModal(true);
          }}
        />
      );
    }

    if (isAdmin && !isClaimed && teamPlayer.invitePhoneNumberList.length) {
      return (
        <Button
          primary
          label="Resend Invite"
          onClick={(e) => {
            e.preventDefault();
            setShowResendInviteModal(true);
          }}
        />
      );
    }

    if (displayClaimButton) {
      return (
        <Button
          primary
          label="Claim Player"
          onClick={(e) => {
            e.preventDefault();
            analytics.track('Claim Player Clicked');
            window.open(`/${teamPlayer._id}?open_claim_modal=1`, '_blank');
          }}
        />
      );
    }
  };

  const getPlayerName = () => {
    if (isAdmin) {
      return `${teamPlayer.nameFirst} ${teamPlayer.nameLast}`;
    } else if (teamPlayer.isNameOnPublicWebsite) {
      return getPrivacyName(
        teamPlayer.nameFirst,
        teamPlayer.nameLast,
        teamPlayer.embedNameFirstLevel,
        teamPlayer.embedNameLastLevel
      );
    } else {
      return '';
    }
  };

  return (
    <SHDLink
      to={claimedPlayer ? `/${claimedPlayer._id}` : `/${teamPlayer._id}`}
    >
      <Card
        fill="vertical"
        pad="small"
        elevation="small"
        gap="large"
        background="#fff"
      >
        <Box direction="row" gap="small" justify="between" fill>
          <Box direction="row" gap="medium">
            {claimedPlayer?.DNOR_imgUrlT150 ? (
              <Avatar src={claimedPlayer?.DNOR_imgUrlT150} size="medium" />
            ) : (
              <Avatar
                background={isClaimed ? 'light-2' : 'gray-2'}
                size="medium"
              >
                {isClaimed && <User size="medium" />}
              </Avatar>
            )}
            <Box gap="xsmall">
              <Box direction="row" gap="small">
                <Text weight={'bold'}>{getPlayerName()}</Text>
                {teamPlayer.claimedPlayerId && (
                  <Box direction="row" gap="xsmall" align="center">
                    <StatusGood color="primary-1" size="18px" />
                  </Box>
                )}
                {isDKPaired && (
                  <Box height="20px" width="20px" round="full">
                    <Image src="dk_icon.png" fit="contain" />
                  </Box>
                )}
              </Box>
              {getSubtitle()}
            </Box>
          </Box>
          <Box align="end">
            {teamPlayer.jerseyNum !== '' && (
              <Text size="xlarge" weight={'bold'}>
                #{teamPlayer.jerseyNum}
              </Text>
            )}
            {isAdmin && (
              <Button
                plain
                label={
                  <Text weight="bold" color="tertiary-1">
                    Edit
                  </Text>
                }
                onClick={(e) => {
                  e.preventDefault();
                  setShowEdit(true);
                }}
              />
            )}
          </Box>
        </Box>
        {getCTA()}
        {showEdit && (
          <EditPlayerLayer
            create={false}
            teamPlayer={teamPlayer}
            claimedPlayer={claimedPlayer ? claimedPlayer : undefined}
            onTeamPlayerUpdated={(updatedTeamPlayer) => {
              setTeamPlayer(updatedTeamPlayer);
            }}
            onClaimedPlayerUpdated={(updatedClaimedPlayer) => {
              setClaimedPlayer(updatedClaimedPlayer);
            }}
            onClose={() => setShowEdit(false)}
            isTeamAdmin={isAdmin}
          />
        )}
        {showResendInviteModal && (
          <ResendInviteModal
            teamPlayer={teamPlayer}
            onClose={() => {
              setShowResendInviteModal(false);
            }}
          />
        )}
        {showReactivateModal && (
          <ReactivatePlayerModal
            teamPlayer={teamPlayer}
            onClose={() => setShowReactivateModal(false)}
            onUpdatedTeamPlayer={(updatedTeamPlayer) => {
              setTeamPlayer(updatedTeamPlayer);
            }}
          />
        )}
      </Card>
    </SHDLink>
  );
};

export default PlayerCard;
