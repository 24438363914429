import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Paragraph,
  ParagraphExtendedProps,
  ResponsiveContext,
} from 'grommet';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import withLayout from '../../helpers/withLayout';
import { BasicLoader } from '../../helpers/Loaders';
import axios from '../../helpers/axios';
import { theFirebase } from '../../services/Firebase/firebase';
import { shd } from '@shd/jslib/models';

import {
  Auth,
  AuthCredential,
  ConfirmationResult,
  PhoneAuthProvider,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from 'firebase/auth';
import PhoneNumberEntry from './PhoneNumberEntry';
import OTPVerification from './OTPVerification';
import AlternativeLoginOptions from './AlternativeLoginOptions';
import useAuthentication from './authHooks';
import { INVITED_USER, ONBOARDING } from '../../constants/routes';
import MultipleUsersForPhoneNumber from './MultipleUsersForPhoneNumber';
import { useQuery } from '../../helpers/utils';
import { AppHostContext } from '../../services/appHost';
import { strogging } from '@shd/jslib/infra';
import { UserContext } from '../../services/Session';

const ErrorDiv = (props: ParagraphExtendedProps) => (
  <Paragraph color="status-error" margin="none" {...props} />
);

enum SignInState {
  PhoneEntry = 'phoneEntry',
  OtpVerification = 'otpVerification',
  AlternativeLogin = 'alternativeLogin',
  MultipleUsers = 'multipleUsers',
}

interface SignInV2Props extends RouteComponentProps {}

const SignInV2: React.FC<SignInV2Props> = ({ history, location, match }) => {
  const userStore = useContext(UserContext);
  const screenSize = useContext(ResponsiveContext);
  const query = useQuery();
  const appHost = useContext(AppHostContext);

  const recaptchaContainerRef = useRef<HTMLDivElement>(null);

  // invited user
  const inviteCode = query.get('code') || undefined;
  const isInvitedUser = !!inviteCode && location.pathname === INVITED_USER;

  // claim player
  const teamPlayerIdToBeClaimed = query.get('claim_player');
  const claimPlayerKey = query.get('key');
  const claimPhoneNumber = query.get('phone');

  const [currentState, setCurrentState] = useState<SignInState>(
    SignInState.PhoneEntry
  );
  const [phoneNumber, setPhoneNumber] = useState('');

  const [sendSMSLoading, setSendSMSLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null); // Update type

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState<{
    message: string;
    urlText?: string;
    url?: string;
  } | null>(null);

  const [existingUser, setExistingUser] = useState<shd.User | undefined>();

  const [possibleUsers, setPossibleUsers] = useState<shd.User[]>([]);

  const { phoneProvider } = theFirebase().getAllAuthProviders();
  const { authenticate } = useAuthentication({
    errorHandler: setError,
    linkAccountHandler: () => {},
    onSuccess:
      appHost.isHosted || inviteCode
        ? undefined
        : () => {
            history.push({
              pathname: ONBOARDING,
            });
          },
    postUrl: '/api/user',
    claimPlayer: teamPlayerIdToBeClaimed || undefined,
    claimPlayerKey: claimPlayerKey || undefined,
    claimPhoneNumber: claimPhoneNumber || undefined,
    toggleLoading: setLoading,
    provider: phoneProvider,
    history,
    location,
    match,
  });

  useEffect(() => {
    if (userStore.authUser) {
      // if user is already authenticated, redirect to the appropriate page
      if (userStore.activeOnboardingTeam) {
        history.push(ONBOARDING);
      } else {
        history.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // Clear the reCAPTCHA when the component unmounts
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        delete window.recaptchaVerifier;
      }
    };
  }, []);

  const clearRecaptcha = () => {
    if (recaptchaContainerRef.current) {
      recaptchaContainerRef.current.innerHTML =
        '<div id="recaptcha-container"></div>';
    }
  };

  const renderRecaptcha = (auth: Auth) => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {
          const appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((cr) => {
              setSendSMSLoading(false);
              // SMS sent. Prompt user to type the code from the message
              window.confirmationResult = cr;
              setConfirmationResult(cr);
              setCurrentState(SignInState.OtpVerification);
            })
            .catch((e) => {
              setSendSMSLoading(false);
              setError({ message: e.message });
              strogging.error('Error sending SMS.', e);

              clearRecaptcha();
            });
        },
        'expired-callback': () => {
          setSendSMSLoading(false);
        },
      }
    );
  };

  const handlePhoneSignIn = () => {
    setError(null);
    setSendSMSLoading(true);
    const auth = getAuth();
    renderRecaptcha(auth);
    window.recaptchaVerifier.verify();
  };

  const checkPhoneNumber = async (credential: AuthCredential) => {
    try {
      const response = await axios.get('/api/signin/check_phone_number', {
        params: { phoneNumber, recaptchaToken: '' },
      });

      const users = response.data.users;
      if (users.length === 0) {
        // new user
        const postParams = {
          phone: phoneNumber,
          sign_up: 1,
          invite_code: isInvitedUser ? inviteCode : undefined,
        };

        authenticate(credential, undefined, postParams);
      } else if (users.length === 1) {
        // existing user
        setExistingUser(users[0]);
        const postParams = {
          phone: phoneNumber,
          existing_user_id: users[0]._id,
          invite_code: isInvitedUser ? inviteCode : undefined,
        };
        authenticate(credential, existingUser, postParams);
      } else {
        // multiple users w/ this phone number
        // log this user out (should we delete the auth record too?)
        await theFirebase().doSignOut();
        setCurrentState(SignInState.MultipleUsers);
        setPossibleUsers(users);
      }
    } catch (e) {
      setError({ message: 'Error fetching accounts. Please try again.' });
    }
  };

  const handleVerifyCode = async (verificationCode: string) => {
    if (!confirmationResult) {
      return {
        success: false,
        message: 'No verification process initialized.',
      };
    }

    const credential = PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      verificationCode
    );

    checkPhoneNumber(credential);
    return { success: true };
  };

  const handleShowAlternativeLogin = () => {
    setCurrentState(SignInState.AlternativeLogin);
  };

  const resetState = () => {
    setCurrentState(SignInState.PhoneEntry);
    setConfirmationResult(null);
    setError(null);
  };

  return (
    <Box
      background="brand"
      height="calc(100vh - 46px)"
      align="center"
      pad="16px"
    >
      {loading && <BasicLoader fullPage />}
      {!!error && <ErrorDiv>{error.message}</ErrorDiv>}
      <div ref={recaptchaContainerRef}>
        <div id="recaptcha-container"></div>
      </div>
      <Box width={screenSize === 'small' ? '100%' : { max: '358px' }}>
        {currentState === SignInState.PhoneEntry && (
          <PhoneNumberEntry
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            sendSMSLoading={sendSMSLoading}
            onContinue={handlePhoneSignIn}
            onShowAlternativeLogin={handleShowAlternativeLogin}
            inviteCode={isInvitedUser ? inviteCode : undefined}
            teamPlayerIdToBeClaimed={teamPlayerIdToBeClaimed}
          />
        )}
        {currentState === SignInState.OtpVerification && (
          <OTPVerification
            phoneNumber={phoneNumber}
            onVerify={handleVerifyCode}
            onBack={() => {
              setPhoneNumber('');
              resetState();
              const auth = getAuth();
              renderRecaptcha(auth);
            }}
            onResend={() => {
              clearRecaptcha();
              handlePhoneSignIn();
            }}
          />
        )}
        {currentState === SignInState.AlternativeLogin && (
          <AlternativeLoginOptions
            inviteCode={isInvitedUser ? inviteCode : undefined}
            claimPlayer={teamPlayerIdToBeClaimed || undefined}
            claimPlayerKey={claimPlayerKey || undefined}
            claimPhoneNumber={claimPhoneNumber || undefined}
            onBack={() => {
              resetState();
              const auth = getAuth();
              renderRecaptcha(auth);
            }}
          />
        )}
        {currentState === SignInState.MultipleUsers && (
          <MultipleUsersForPhoneNumber
            users={possibleUsers}
            onBack={() => setCurrentState(SignInState.PhoneEntry)}
            onContinue={(selectedProvider) => {
              const postParams = {
                invite_code: isInvitedUser ? inviteCode : undefined,
              };
              authenticate(undefined, undefined, postParams, selectedProvider);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default withRouter(withLayout(SignInV2));
