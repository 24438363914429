import {
  PaywallCtaModes,
  PaywallFeatureListItem,
  PaywallLockModes,
} from './paywall.types';

export const paywallFeaturesCopy: Array<PaywallFeatureListItem> = [
  {
    title: 'Automatic highlights & play-by-play videos:',
    copy: 'Access all player videos on sidelineHD.',
  },
  {
    title: 'In-game texts:',
    copy: 'Get real-time alerts and highlights for up to 5 phone numbers, so family and friends can enjoy the action before the inning ends.',
  },
  {
    title: 'Public profile',
    copy: 'Provide unlimited access to your player\u0027s highlights with a sharable profile link.',
  },
];

export const getPaywallCtaCopy = (
  annualPrice: string,
  mode: PaywallCtaModes
) => {
  let headline =
    'Unlock real-time highlights, HitScope recaps, and player profile access.';
  const copy = `Only ${annualPrice}/year. Cancel anytime.`;

  switch (mode) {
    case 'phone':
      headline =
        'Game alerts and real-time highlights texts to you and 5 of your player\u0027s biggest fans!';
      break;
    case 'fullAccess':
      headline =
        'Unlock full access to player highlights and HitScope for you and your family.';
      break;
    case 'playerSettings':
      headline =
        'Subscribe for unlimited sharing of your player\u0027s profile and highlight gallery.';
      break;
    default:
      break;
  }

  return {
    headline,
    copy,
  };
};

export const getPaywallFinePrintCopy = (price: string) => {
  return `By subscribing you authorize Diamond Kinetics to charge ${price} on any payment method on your account, based on the terms, until you cancel. To avoid charges cancel up to 48 hours before billing date.`;
};

export const getPaywallLockCopy = (
  isClaimed: boolean,
  isLoggedIn: boolean,
  isPrivate: boolean,
  mode?: PaywallLockModes
) => {
  let headline = isPrivate
    ? 'This player has their highlights and videos set to private.'
    : 'Public highlights and videos are not currently available for this player.';

  let copy = '';

  const signIn = {
    linkText: '',
    linkUrl: '',
  };

  const learnMore = {
    linkText: '',
    linkUrl: '',
  };

  if (!isLoggedIn) {
    copy = isClaimed
      ? 'to your sidelineHD account to view. Pro access may be required based on your team affiliation.'
      : 'to your sidelineHD account to claim this player. Pro access may be required based on your team affiliation.';
    signIn.linkText = 'Sign In';
    signIn.linkUrl = '/signin';
  } else {
    learnMore.linkText = 'Learn more';
    learnMore.linkUrl = 'https://help.sidelinehd.com/hc/en-us';
  }

  switch (mode) {
    case 'hitscope':
      headline = 'Team HitScope is not available for public viewing.';
      break;
    case 'game':
      headline = 'Game page videos are not available when logged out.';
      break;
    case 'gameHitscope':
      headline =
        'Public highlights and videos are not currently available for this game.';
      break;
    case 'download':
      headline = 'Downloading is not available';
      break;
    case 'teamPlayer':
    default:
      break;
  }

  const paywallLockCopyIsPrivate = {
    headline,
    copy,
    learnMore,
    signIn,
  };

  const paywallLockCopyIsUnavailable = {
    headline,
    copy,
    learnMore,
    signIn,
  };

  if (isPrivate) {
    return paywallLockCopyIsPrivate;
  } else {
    return paywallLockCopyIsUnavailable;
  }
};

export const paywallHeadline = 'Unlock the most from sidelineHD with Pro.';
