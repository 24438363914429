import { useCallback, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import axios from '../../helpers/axios';
import { shd } from '@shd/jslib/models';
import { COMBINED_BBSB_KEY } from '../../constants/strings';
import { ClipFeedClip } from './player.types';
import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { Purchases } from '@revenuecat/purchases-js';
import { ViewerEntitlementStatus } from '../../components/Paywall/paywall.types';
import { strogging } from '@shd/jslib/infra';

const useTeamPlayer = (teamPlayerId: string) => {
  const [teamPlayer, setTeamPlayer] = useState<shd.TeamPlayer>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!teamPlayerId) {
      return;
    }
    setLoading(true);
    axios
      .get(`/api/team_player/${teamPlayerId}`)
      .then((response) => {
        setTeamPlayer(response.data.teamPlayer);
        setError(null);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setTeamPlayer(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamPlayerId]);

  return { teamPlayer, error, loading };
};

export interface PlayerPrimaryInfo {
  player: shd.Player;
  teams: shd.Team[];
  claimedPlayerId?: string;
  claimedPlayerHandle?: string;
}
const usePlayerPrimaryInfo = (playerId: string | undefined) => {
  const [playerInfo, setPlayerInfo] = useState<PlayerPrimaryInfo | null>(null);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!playerId) {
      return;
    }
    setLoading(true);
    axios
      .get(`/api/player/${playerId}`)
      .then((response) => {
        setPlayerInfo(response.data);
        setLoading(false);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setLoading(false);
      });
  }, [playerId]);

  return { playerInfo, error, loading };
};

const usePlayerLiveAssets = (playerId: string) => {
  const [liveAssets, setLiveAssets] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/player/${playerId}/live_assets`)
      .then((response) => {
        setLiveAssets(response.data);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [playerId]);

  return liveAssets;
};

const useVideoDimensions = () => {
  let width = window.innerWidth;
  if (width > 425) {
    width = 425;
  }
  const height = width * (9 / 16);
  return { width: width, height: height };
};

const usePlayerReels = (playerId: string) => {
  const [reels, setReels] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/player/${playerId}/reels`)
      .then((response) => {
        setReels(response.data || []);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
      });
  }, [playerId]);

  return reels;
};

const usePlayerGameSummaries = (playerId: string, selectedSport: string) => {
  const [gameSummaries, setGameSummaries] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (selectedSport !== COMBINED_BBSB_KEY) {
      setGameSummaries([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get(`/api/player/${playerId}/game_summary_videos`)
      .then((response) => {
        setGameSummaries(response.data.gameSummaries || []);
        setError(null);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setGameSummaries([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [playerId, selectedSport]);

  return { gameSummaries, error, loading };
};

const usePinnedClips = (playerId: string | null, claimed: boolean) => {
  const [pinnedClips, setPinnedClips] = useState<ClipFeedClip[]>([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!playerId || !claimed) {
      setPinnedClips([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    axios
      .get(`/api/player/${playerId}/pinned_clips`)
      .then((response) => {
        setPinnedClips(response.data || []);
        setError(null);
      })
      .catch((respError) => {
        Sentry.captureException(respError);
        setError(respError);
        setPinnedClips([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [playerId, claimed]);

  return { pinnedClips, error, loading };
};

const useIsEntitledToViewPlayer = (
  playerId: string,
  userId: string | undefined,
  selectedSport?: string,
  teamId?: string
) => {
  const userStore = useContext(UserContext);
  const isMonetizationEnabled = useIsFeatureEnabled('monetization', userStore);

  const [status, setStatus] = useState<ViewerEntitlementStatus | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getIsPlayerPublic = useCallback(async () => {
    const response = await axios.get(`/api/player/${playerId}/is_public`);
    return response.data.isPublic;
  }, [playerId]);

  useEffect(() => {
    const checkViewEntitlement = async () => {
      // When the `monetization` feature flag is off, everyone is entitled to view the clips, etc
      if (!isMonetizationEnabled) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
        setLoading(false);
        return;
      }

      // Non-BBSB sports are still free.
      if (selectedSport !== COMBINED_BBSB_KEY) {
        setStatus(ViewerEntitlementStatus.ENTITLED);
        setLoading(false);
        return;
      }

      if (!playerId && !teamId) {
        setStatus(null);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        let loggedInUserIsEntitled = false;

        if (userId) {
          try {
            const customerInfo =
              await Purchases.getSharedInstance().getCustomerInfo();
            const activeEntitlements = customerInfo.entitlements.active;
            loggedInUserIsEntitled =
              activeEntitlements['Pro Access'] !== undefined;

            const isPublic = await getIsPlayerPublic();

            const userManagesPlayer = userStore.userPlayers.some(
              (p) => p._id === playerId
            );

            if (loggedInUserIsEntitled && userManagesPlayer) {
              strogging.log('User is entitled and manages player');
              setStatus(ViewerEntitlementStatus.ENTITLED);
              return;
            } else if (loggedInUserIsEntitled && isPublic) {
              strogging.log('User is entitled but player is public');
              setStatus(ViewerEntitlementStatus.ENTITLED);
              return;
            } else if (loggedInUserIsEntitled && !isPublic) {
              strogging.log('User is entitled but player is private');
              setStatus(ViewerEntitlementStatus.ENTITLED_BUT_PRIVATE);
              return;
            }
          } catch (e) {
            Sentry.captureException(e);
          }
        }

        if (!loggedInUserIsEntitled) {
          const response = await axios.get(
            `/api/player/${playerId}/is_entitled_to_view`
          );
          setStatus(response.data.status);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
        setStatus(null);
      } finally {
        setLoading(false);
      }
    };

    checkViewEntitlement();
  }, [
    playerId,
    teamId,
    userId,
    isMonetizationEnabled,
    getIsPlayerPublic,
    selectedSport,
    userStore.userTeams,
    userStore.coachTeamIds,
    userStore.userPlayers,
  ]);

  return { status, loading, error };
};

const useCanEditMonetizedPlayerFields = (
  playerId: string,
  userId: string,
  selectedSport?: string
) => {
  const userStore = useContext(UserContext);
  const isMonetizationEnabled = useIsFeatureEnabled('monetization', userStore);

  const [canEditMonetizedFields, setCanEditMonetizedFields] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const checkIsTeamManagerOfPlayer = useCallback(
    async (teamIds: string[]) => {
      try {
        const response = await axios.get(
          `/api/claimed_player/${playerId}/team_ids`
        );
        const playerTeamIds = response.data.teamIds;
        return playerTeamIds.some((tId: string) => teamIds.includes(tId));
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    [playerId]
  );

  useEffect(() => {
    const checkEntitlement = async () => {
      if (!isMonetizationEnabled) {
        setCanEditMonetizedFields(true);
        setLoading(false);
        return;
      }

      if (selectedSport !== COMBINED_BBSB_KEY) {
        setCanEditMonetizedFields(true);
        setLoading(false);
        return;
      }

      // return true if player is entitled OR the user is entitled OR the user is a team manager

      const isTeamManager = await checkIsTeamManagerOfPlayer(
        userStore.userTeams.map((t) => t._id)
      );

      if (isTeamManager) {
        setCanEditMonetizedFields(true);
        setLoading(false);
        return;
      }

      try {
        const customerInfo =
          await Purchases.getSharedInstance().getCustomerInfo();
        const activeEntitlements = customerInfo.entitlements.active;
        const userIsEntitled = activeEntitlements['Pro Access'] !== undefined;

        if (userIsEntitled) {
          setCanEditMonetizedFields(true);
          setLoading(false);
          return;
        }

        const response = await axios.get(
          `/api/player/${playerId}/is_entitled_to_view?ignore_user=true`
        );
        const playerIsEntitled =
          response.data.status === ViewerEntitlementStatus.ENTITLED;
        setCanEditMonetizedFields(playerIsEntitled);
        setLoading(false);
      } catch (e) {
        setError(e instanceof Error ? e.message : 'An error occurred');
        Sentry.captureException(e);
      }
    };

    checkEntitlement();
  }, [
    playerId,
    userStore.userTeams,
    userStore.coachTeamIds,
    userStore.userPlayers,
    isMonetizationEnabled,
    checkIsTeamManagerOfPlayer,
    selectedSport,
  ]);

  return { canEditMonetizedFields, loading, error };
};

export {
  useTeamPlayer,
  usePlayerPrimaryInfo,
  usePlayerLiveAssets,
  useVideoDimensions,
  usePlayerReels,
  usePlayerGameSummaries,
  usePinnedClips,
  useIsEntitledToViewPlayer,
  useCanEditMonetizedPlayerFields,
};
