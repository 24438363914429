// Packages
import React from 'react';
import { getPaywallLockCopy } from './paywall.copy';

// Components
import { Box, Heading, Image } from 'grommet';
import { PaywallCopy, PaywallLink } from './paywall.styles';

// Types
import { PaywallLockProps } from './paywall.types';

const PaywallLock: React.FC<PaywallLockProps> = ({
  isClaimed = true,
  isLoggedIn = false,
  isPrivate,
  mode,
}) => {
  const { headline, copy, signIn, learnMore } = getPaywallLockCopy(
    isClaimed,
    isLoggedIn,
    isPrivate,
    mode
  );

  return (
    <>
      <Box align="center" className="paywall-lock" direction="column">
        <Image
          alt="Paywall Lock Image"
          height="154px"
          src="/paywall-lock.svg"
          width="154px"
          margin={{
            bottom: '-0.5rem',
            left: '0',
            right: '0',
            top: '0',
          }}
        />

        <Heading
          level={3}
          margin={{
            bottom: '0.625rem',
            left: '0',
            right: '0',
            top: '0',
          }}
          size="1.125rem"
          style={{ lineHeight: '1.333' }}
          textAlign="center"
          weight="bold"
        >
          {headline}
        </Heading>

        {!isLoggedIn && (
          <>
            <PaywallCopy
              color="#4a4a4a"
              margin="0 0 0.625rem"
              size="0.75rem"
              style={{ lineHeight: '1.333' }}
              textAlign="center"
            >
              <a href={signIn.linkUrl} style={{ fontWeight: '600' }}>
                {signIn.linkText}
              </a>
              &nbsp;{copy}
            </PaywallCopy>
          </>
        )}

        {learnMore.linkText !== '' && learnMore.linkUrl !== '' && (
          <PaywallLink
            color="#1a1a1a"
            href={learnMore.linkUrl}
            label={learnMore.linkText}
            margin="0"
            size="0.75rem"
            style={{
              lineHeight: '1',
              opacity: '0.75',
              transition: 'opacity 0.1s linear',
            }}
            weight="400"
          />
        )}
      </Box>
    </>
  );
};

export default PaywallLock;
