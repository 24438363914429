// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */

/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */

import React, { useContext, useEffect } from 'react';
import useState from 'react-usestateref';

import { compose } from 'recompose';
import { withRouter, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Text,
  Spinner,
  Layer,
  Anchor,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  Grommet,
  DataTable,
  CheckBox,
} from 'grommet';
import { grommet } from 'grommet/themes';
import { DownloadOption, FormClose, FormNext, StatusGood } from 'grommet-icons';
import { DateTime } from 'luxon';

import HLSPlayer from '../../helpers/HLSPlayer';
import withLayout from '../../helpers/withLayout';
import axios from '../../helpers/axios';
import { BasicLoader } from '../../helpers/Loaders';
import { formatHalfInningNum, useQuery } from '../../helpers/utils';
import { analytics } from '../../services/analytics';
import useZendeskWidget from '../../helpers/useZendeskWidget';

import { UserContext } from '../../services/Session';
import { useIsFeatureEnabled } from '../../helpers/utils';
import { useIsEntitledToViewGame } from '../Game/gameHooks';
import { usePaywall } from '../../components/Paywall/paywall.hooks';
import {
  ViewerEntitlementStatus,
  PaywallContext,
} from '../../components/Paywall/paywall.types';
import Paywall from '../../components/Paywall/Paywall';
import PaywallLock from '../../components/Paywall/PaywallLock';

const ClipsDataTable = withRouter(({ data, ...props }) => {
  const [selectedId, setSelectedId] = useState(props.selectedId);

  useEffect(() => {
    setSelectedId(props.selectedId);
  }, [props.selectedId]);

  const columns = [
    {
      property: 'date',
      header: <Text>Date</Text>,
      render: (datum) =>
        datum.date &&
        new Date(datum.date).toLocaleDateString('en-US', {
          year: '2-digit',
          month: 'numeric',
          day: 'numeric',
        }),
    },
    {
      property: 'inning',
      header: 'Inning',
    },
    {
      property: 'outcome',
      header: 'Outcome',
    },
    {
      property: 'more',
      header: 'More',
      align: 'start',
      render: (datum) => (
        <Button
          alignSelf="center"
          justify="end"
          plain
          reverse
          gap="xxsmall"
          label={<Text size="xsmall">Full AB</Text>}
          icon={<FormNext size="small" />}
          onClick={(e) => {
            analytics.track('HitScope Full AB Click', {
              clipId: datum.id,
            });
            e.stopPropagation();
            if (props.playerId) {
              window.open(`/${props.playerId}/c/${datum.id}`, '_blank');
            } else {
              window.open(`/${datum.playerId}/c/${datum.id}`, '_blank');
            }
          }}
        />
      ),
    },
  ];

  const rowProps = {};
  rowProps[selectedId] = { background: 'light-5' };

  return (
    <Grommet theme={grommet}>
      <Box align="center">
        <DataTable
          fill
          border={false}
          columns={columns}
          data={data}
          primaryKey="id"
          rowProps={rowProps}
          onClickRow={(event) => {
            setSelectedId(event.datum.id);
            props.onSelected(event.datum);
          }}
        />
      </Box>
    </Grommet>
  );
});

// TODO: DRY
const renderScoringByInning = (data) => {
  const {
    J_opponentName,
    scoreOurName,
    scoreWeAreHome,
    homeScore,
    awayScore,
    homeRunsByInning,
    awayRunsByInning,
  } = data;

  let homeTeamName;
  let awayTeamName;

  if (scoreWeAreHome) {
    homeTeamName = scoreOurName;
    awayTeamName = J_opponentName;
  } else {
    homeTeamName = J_opponentName;
    awayTeamName = scoreOurName;
  }

  let headers;
  if (homeRunsByInning.length > awayRunsByInning.length) {
    headers = ['Team']
      .concat(homeRunsByInning.map((_, inning) => inning + 1))
      .concat(['R']);
  } else {
    headers = ['Team']
      .concat(awayRunsByInning.map((_, inning) => inning + 1))
      .concat(['R']);
  }

  const homeRow = [homeTeamName]
    .concat(homeRunsByInning.map((runs) => runs))
    .concat([homeScore]);
  const awayRow = [awayTeamName]
    .concat(awayRunsByInning.map((runs) => runs))
    .concat([awayScore]);

  const scoresTable = (
    <Table>
      <TableHeader>
        <TableRow>
          {headers.map((inning) => (
            <TableCell
              key={`inning-header-${inning}`}
              scope="col"
              border="bottom"
            >
              <Text weight="bold">{inning}</Text>
            </TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow key="away-row">
          {awayRow.map((x, i) => (
            <TableCell key={`runs-${i + 1}`}>
              <Text>{x}</Text>
            </TableCell>
          ))}
        </TableRow>
        <TableRow key="home-row">
          {homeRow.map((x, i) => (
            <TableCell key={`runs-${i + 1}`}>
              <Text>{x}</Text>
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );

  return <Box margin={{ top: 'medium' }}>{scoresTable}</Box>;
};

const GameHitScope = ({ history }) => {
  const { gameid: gameId } = useParams();
  const query = useQuery();

  const [loading, setLoading] = useState(false);

  const [gameData, setGameData] = useState(null);

  const [isConvertingClips, setIsConvertingClips] = useState(false);

  const [clips, setClips] = useState([]);
  const [m3u8Text, setM3u8Text] = useState();
  const [selectedClip, setSelectedClip] = useState();
  const [manualSelectedClip, setManualSelectedClip] = useState();

  const [teamSelection, setTeamSelection] = useState([
    query.get('us') === '1',
    query.get('them') === '1',
  ]);

  const [toast, setToast] = useState(null);
  const [error, setError] = useState(null);

  useZendeskWidget();

  const userStore = useContext(UserContext);
  const isMonetization = useIsFeatureEnabled('monetization', userStore);

  const { status: entitledStatus, loading: entitledLoading } =
    useIsEntitledToViewGame(gameData);
  const { handleUnlockAccess } = usePaywall();

  const getClips = (shouldFetch = true) => {
    setLoading(true);
    if (shouldFetch) {
      axios
        .get(
          `/api/hitscope?game_id=${gameId}&us=${teamSelection[0]}&them=${teamSelection[1]}`
        )
        .then((response) => {
          const { data } = response;
          setClips(
            response.data.clips.map((clip) => {
              const date = DateTime.fromMillis(
                clip.clipStartTs * 1000
              ).toLocal();
              const opponent = `${clip.teamNameMed[0]} @ ${clip.teamNameMed[1]}`;

              const playerId = clip.J_claimedPlayerList[0];

              return {
                id: clip.id,
                date,
                opponent,
                inning: formatHalfInningNum(clip.inningNum),
                outcome: clip.textDescriptionBrief,
                more: 'More',
                startTs: clip.startTs,
                endTs: clip.endTs,
                fileDownloadUrl: clip.fileDownloadUrl,
                playerId,
              };
            })
          );
          setM3u8Text(data.m3u8_text);

          if (response.data.clips.length > 0) {
            setSelectedClip(response.data.clips[0]);
          }

          setIsConvertingClips(response.data.converting);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setError(err.response.data.message);
          } else {
            setError('Something went wrong. Please try again.');
          }
        });
    }
  };

  const getGameData = () => {
    axios.get(`/api/team_game/${gameId}`).then((response) => {
      setLoading(false);
      setGameData(response.data);
    });
  };

  useEffect(() => {
    getClips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSelection]);

  useEffect(() => {
    analytics.track('HitScope View', {
      type: 'Game',
    });

    getGameData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClipChanged = (newClip) => {
    setSelectedClip(newClip);
  };

  if (!gameId) {
    return <BasicLoader size={200} fullPage />;
  }

  const clipsEmptyState = <Box>No clips in the selected time period</Box>;

  const renderClipsList = () => {
    if (loading) {
      return (
        <Box gap="small">
          <Spinner
            size="medium"
            message="Start Built-in Spinner Announcement"
          />
          <Text>Loading HitScope clips...</Text>
        </Box>
      );
    }

    if (clips.length > 0 && selectedClip) {
      return (
        <ClipsDataTable
          data={clips}
          selectedId={selectedClip.id}
          onSelected={(clip) => {
            analytics.track('HitScope Timestamp Clicked', {
              type: 'game',
              gameId,
            });
            setManualSelectedClip(clip);
          }}
        />
      );
    }

    if (isConvertingClips) {
      return (
        <Box>Converting clips... Refresh page to start using HitScope!</Box>
      );
    }

    return clipsEmptyState;
  };

  const videoLoading = (
    <Box fill="horizontal" gap="small">
      <Spinner size="medium" message="Start Built-in Spinner Announcement" />
      <Text>Loading HitScope video...</Text>
    </Box>
  );

  const downloadToast = (
    <Layer
      position="bottom"
      modal={false}
      margin={{ vertical: 'medium' }}
      onEsc={() => {
        setToast(null);
      }}
      responsive={false}
      plain
    >
      <Box
        align="center"
        direction="row"
        gap="small"
        justify="between"
        round="medium"
        elevation="medium"
        pad="medium"
        background="status-ok"
      >
        <Box gap="small">
          <Box align="center" direction="row" gap="small">
            <StatusGood />
            {toast && <Text>{toast.label}</Text>}
          </Box>
          {toast && toast.url ? (
            <Anchor href={toast.url} target="_blank">
              Download here
            </Anchor>
          ) : null}
        </Box>

        <Button
          icon={<FormClose />}
          onClick={() => {
            setToast(null);
          }}
          plain
        />
      </Box>
    </Layer>
  );

  const onDownloadClicked = () => {
    analytics.track('Download HitScope Clicked', {
      type: 'Game',
    });

    const toastData = {
      label: `We’re preparing your video from ${clips.length} at-bat${
        clips.length > 1 ? 's' : ''
      }.
      This may take a bit - please keep this page open!`,
      background: 'status-ok',
      duration: 60 * 1000,
    };
    setToast(toastData);
    axios
      .get(
        `/api/hitscope?download=1&game_id=${gameId}&us=${teamSelection[0]}&them=${teamSelection[1]}`,
        {
          // responseType: 'blob',
          timeout: 600 * 1000,
        }
      )
      .then((response) => {
        setToast({
          label: 'Your HitScope video is ready!',
          url: response.data.url,
        });
      })
      .catch(() => {
        setToast({
          label: 'Uh oh something went wrong.',
        });
      });
  };

  const teamSelector = gameData ? (
    <Grommet
      theme={{
        checkBox: {
          color: '#066d99',
        },
      }}
    >
      <Box gap="small">
        <Text>Show clips from...</Text>
        <CheckBox
          label={gameData.scoreOurName}
          name="name"
          value="0"
          checked={teamSelection[0]}
          onChange={(event) => {
            query.set('us', event.target.checked ? 1 : 0);
            history.replace({ search: query.toString() });
            setTeamSelection([event.target.checked, teamSelection[1]]);
          }}
        />
        <CheckBox
          label={gameData.J_opponentName}
          name="name"
          value="1"
          checked={teamSelection[1]}
          onChange={(event) => {
            query.set('them', event.target.checked ? 1 : 0);
            history.replace({ search: query.toString() });
            setTeamSelection([teamSelection[0], event.target.checked]);
          }}
        />
      </Box>
    </Grommet>
  ) : null;

  return (
    <>
      {isMonetization ? (
        <>
          {entitledLoading ? (
            <BasicLoader />
          ) : (
            <>
              {entitledStatus ===
                ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_OUT && (
                <PaywallLock
                  mode="gameHitscope"
                  isLoggedIn={!!userStore.authUser}
                  isPrivate={false}
                />
              )}
              {entitledStatus ===
                ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN && (
                <Paywall
                  isOpen={
                    entitledStatus ===
                    ViewerEntitlementStatus.NOT_ENTITLED_LOGGED_IN
                  }
                  context={PaywallContext.GAME_HITSCOPE}
                  onClose={() => history.goBack()}
                  onUnlockAccess={(term) => handleUnlockAccess(term)}
                />
              )}
              {entitledStatus === ViewerEntitlementStatus.ENTITLED && (
                <Box gap="large" width="xlarge" margin="0 auto" pad="medium">
                  <Box justify="between" direction="row">
                    {/* <Text size="large" weight="bold">HitScope Game Summary</Text> */}
                    <img
                      src="/HitScopeLogo.svg"
                      alt="hitscope logo"
                      style={{ width: '50%', marginTop: '2vh' }}
                    />
                  </Box>
                  {gameData &&
                    gameData.scoreGameState > 10 &&
                    renderScoringByInning(gameData)}
                  {gameData ? teamSelector : null}

                  {m3u8Text ? (
                    <HLSPlayer
                      m3u8Text={m3u8Text}
                      clips={clips}
                      style={{ width: '100%' }}
                      onClipChanged={onClipChanged}
                      seekToClip={manualSelectedClip}
                    />
                  ) : (
                    videoLoading
                  )}
                  <Box direction="row" justify="between">
                    <Button
                      plain
                      color="secondary-1"
                      icon={<DownloadOption size="20px" color="secondary-1" />}
                      label={<Text size="small">Download</Text>}
                      id="download-clip-unclaimed"
                      onClick={onDownloadClicked}
                    />
                  </Box>

                  <Text>
                    Have feedback?{' '}
                    <Anchor
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeNT87DcBq1fMOrfDKLl_sBEmJxN8gRZI-rTIqpOSI3BW7jKw/viewform?usp=sf_link"
                      target="_blank"
                    >
                      Let us know!
                    </Anchor>
                  </Text>
                  <Box gap="small">
                    <Text>Jump to any AB by clicking on it.</Text>
                    {renderClipsList()}
                  </Box>
                  {error && <Text color="red">{error}</Text>}
                  {toast ? downloadToast : null}
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <Box gap="large" width="xlarge" margin="0 auto" pad="medium">
          <Box justify="between" direction="row">
            {/* <Text size="large" weight="bold">HitScope Game Summary</Text> */}
            <img
              src="/HitScopeLogo.svg"
              alt="hitscope logo"
              style={{ width: '50%', marginTop: '2vh' }}
            />
          </Box>
          {gameData &&
            gameData.scoreGameState > 10 &&
            renderScoringByInning(gameData)}
          {gameData ? teamSelector : null}

          {m3u8Text ? (
            <HLSPlayer
              m3u8Text={m3u8Text}
              clips={clips}
              style={{ width: '100%' }}
              onClipChanged={onClipChanged}
              seekToClip={manualSelectedClip}
            />
          ) : (
            videoLoading
          )}
          <Box direction="row" justify="between">
            <Button
              plain
              color="secondary-1"
              icon={<DownloadOption size="20px" color="secondary-1" />}
              label={<Text size="small">Download</Text>}
              id="download-clip-unclaimed"
              onClick={onDownloadClicked}
            />
          </Box>

          <Text>
            Have feedback?{' '}
            <Anchor
              href="https://docs.google.com/forms/d/e/1FAIpQLSeNT87DcBq1fMOrfDKLl_sBEmJxN8gRZI-rTIqpOSI3BW7jKw/viewform?usp=sf_link"
              target="_blank"
            >
              Let us know!
            </Anchor>
          </Text>
          <Box gap="small">
            <Text>Jump to any AB by clicking on it.</Text>
            {renderClipsList()}
          </Box>
          {error && <Text color="red">{error}</Text>}
          {toast ? downloadToast : null}
        </Box>
      )}
    </>
  );
};

export default compose(withLayout, withRouter)(GameHitScope);
