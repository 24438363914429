/* eslint-disable no-param-reassign */
import {
  FirebaseApp,
  FirebaseError,
  getApps,
  initializeApp,
} from 'firebase/app';
import {
  Analytics,
  CustomParams,
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  Auth,
  AuthProvider,
  linkWithCredential,
  AuthCredential,
  User,
  AuthError,
  linkWithPopup,
  signInWithRedirect,
  signInWithCustomToken,
  PhoneAuthProvider,
  signInWithCredential,
} from 'firebase/auth';
import {
  getFirestore,
  enableMultiTabIndexedDbPersistence,
  collection,
  doc,
  deleteField,
  getDoc,
  onSnapshot,
  query,
  where,
  setDoc,
  updateDoc,
  serverTimestamp,
  Firestore,
  CollectionReference,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

import {
  RemoteConfig,
  getAll,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';
import { docStorage, shd } from '@shd/jslib/models';
import { strogging } from '@shd/jslib/infra';
import { theGlobalAppHost } from '../appHost';

const localConfig = {
  apiKey: 'AIzaSyBpxqCwR673sesmK22Ag1QaTq7hj9sfLVo',
  authDomain: 'shd-web-dev-001.firebaseapp.com',
  databaseURL: 'https://shd-web-dev-001.firebaseio.com',
  projectId: 'shd-web-dev-001',
  storageBucket: 'shd-web-dev-001.appspot.com',
  messagingSenderId: '864405285441',
  appId: '1:864405285441:web:ecec966737c4f903b374c0',
  measurementId: 'G-C6VP658MSG',
};

const testConfig = {
  apiKey: 'AIzaSyBpxqCwR673sesmK22Ag1QaTq7hj9sfLVo',
  authDomain: 'shd-web-dev-001.firebaseapp.com',
  databaseURL: 'https://shd-web-dev-001.firebaseio.com',
  projectId: 'shd-web-dev-001',
  storageBucket: 'shd-web-dev-001.appspot.com',
  messagingSenderId: '864405285441',
  appId: '1:864405285441:web:ecec966737c4f903b374c0',
  measurementId: 'G-C6VP658MSG',
};

const prodConfig = {
  apiKey: 'AIzaSyBdCMeQBSOTD4GYiHCXLXMyn9l_b_XyedY',
  authDomain: 'shd-001.firebaseapp.com',
  databaseURL: 'https://shd-001.firebaseio.com',
  projectId: 'shd-001',
  storageBucket: 'shd-001.appspot.com',
  messagingSenderId: '439745628468',
  appId: '1:439745628468:web:83fc845e266a0837e47d61',
  measurementId: 'G-XHY214267N',
};

let config = {};
const { NODE_ENV, REACT_APP_FIREBASE_CONFIG } = process.env;
const firebaseConfigEnv = REACT_APP_FIREBASE_CONFIG;

strogging.log('Firebase:initializeApp', {
  NODE_ENV,
  REACT_APP_FIREBASE_CONFIG,
  firebaseConfigEnv,
});
if (firebaseConfigEnv === 'production') {
  config = prodConfig;
} else if (firebaseConfigEnv === 'development') {
  config = localConfig;
} else {
  config = testConfig;
}

export type FirebaseAuthError = AuthError;
export type FirebaseAuthCredential = AuthCredential;

export function isFirebaseError(err: unknown): err is FirebaseError {
  return (
    err instanceof FirebaseError ||
    (typeof err === 'object' &&
      err !== null &&
      'code' in err &&
      'message' in err)
  );
}

export function isFirebaseAuthError(err: unknown): err is AuthError {
  return isFirebaseError(err) && err.code.startsWith('auth/');
}

export class Firebase {
  app: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  analytics: Analytics;
  remoteConfig: RemoteConfig;

  googleAuthProvider: GoogleAuthProvider;
  facebookAuthProvider: FacebookAuthProvider;
  appleAuthProvider: OAuthProvider;
  phoneAuthProvider: PhoneAuthProvider;

  scoringCollection: CollectionReference<docStorage.ScoringDoc>;
  teamCollection: CollectionReference<docStorage.TeamDoc>;
  fbGroupInstallsCollection: CollectionReference<docStorage.FbGroupInstallDoc>;

  teamGameCollection = (
    teamId: string
  ): CollectionReference<docStorage.GameDoc> =>
    collection(this.teamCollection, teamId, 'teamgames');
  teamGameScoreboardCollection = (
    teamId: string
  ): CollectionReference<docStorage.ScoreboardDoc> =>
    collection(this.scoringCollection, teamId, 'scoreboards');

  constructor() {
    this.app = getApps().length ? getApps()[0] : initializeApp(config);

    this.auth = getAuth(this.app);
    this.firestore = getFirestore(this.app);
    this.analytics = getAnalytics(this.app);
    this.remoteConfig = getRemoteConfig(this.app);
    this.remoteConfig.settings.minimumFetchIntervalMillis = 10 * 1000;

    enableMultiTabIndexedDbPersistence(this.firestore).catch((err) => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        strogging.log(err.code, err);
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        strogging.log(err.code, err);
      }
    });

    this.googleAuthProvider = new GoogleAuthProvider();
    this.facebookAuthProvider = new FacebookAuthProvider();
    this.appleAuthProvider = new OAuthProvider('apple.com');
    this.phoneAuthProvider = new PhoneAuthProvider(this.auth);

    this.scoringCollection = collection(
      this.firestore,
      '/pulldata-sscore-v001'
    );
    this.teamCollection = collection(
      this.firestore,
      '/team01'
    ) as CollectionReference<docStorage.TeamDoc>;
    this.fbGroupInstallsCollection = collection(
      this.firestore,
      '/fb-group-installs'
    );
  }

  // *** Remote Config API ***
  getAllRemoteConfig = () => getAll(this.remoteConfig);

  getRemoteConfigValue = (key: string) => getValue(this.remoteConfig, key);

  // *** Analytics API ***
  logAnalyticsEvent = (
    eventName: string,
    eventParams?:
      | {
          [key: string]: unknown;
        }
      | undefined
  ) => logEvent(this.analytics, eventName, eventParams);

  setAnalyticsUserId = (userId: string | null) =>
    setUserId(this.analytics, userId);

  setAnalyticsUserProperties = (userProperties: CustomParams) =>
    setUserProperties(this.analytics, userProperties);

  // *** Auth API ***

  getAllAuthProviders = () => {
    const googleProvider = this.googleAuthProvider;
    googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');

    const facebookProvider = this.facebookAuthProvider;
    facebookProvider.addScope('email');
    this.facebookAuthProvider.setCustomParameters({
      display: 'popup',
    });

    const appleProvider = this.appleAuthProvider;
    appleProvider.addScope('email');
    appleProvider.addScope('name');

    const phoneProvider = this.phoneAuthProvider;

    return { googleProvider, facebookProvider, appleProvider, phoneProvider };
  };

  doSignInWithPopup = (provider: AuthProvider) =>
    signInWithPopup(this.auth, provider);

  doSignInWithCustomToken = (token: string) =>
    signInWithCustomToken(this.auth, token);

  doSignInWithRedirect = (provider: AuthProvider) =>
    signInWithRedirect(this.auth, provider);

  doSignInWithCredential = (credential: AuthCredential) =>
    signInWithCredential(this.auth, credential);

  doLinkWithPopup = (user: User, provider: AuthProvider) =>
    linkWithPopup(user, provider);

  doFetchSignInMethodsForEmail = (email: string) =>
    fetchSignInMethodsForEmail(this.auth, email);

  doLinkWithCredential = (user: User, credential: AuthCredential) =>
    linkWithCredential(user, credential);

  getErrorCredential = (error: FirebaseAuthError, providerId: string) => {
    if (providerId === this.googleAuthProvider.providerId) {
      return GoogleAuthProvider.credentialFromError(error);
    } else if (providerId === this.facebookAuthProvider.providerId) {
      return FacebookAuthProvider.credentialFromError(error);
    } else if (providerId === this.appleAuthProvider.providerId) {
      return OAuthProvider.credentialFromError(error);
    } else {
      return null;
    }
  };

  doSignOut = async () => {
    strogging.log('Starting Firebase sign-out');
    await this.auth.signOut();
    strogging.log('Firebase sign-out completed');

    const appHost = theGlobalAppHost();
    if (appHost.isHosted) {
      appHost.service.notifications.signOut();
      const shdapp = window.shdApp;
      if (shdapp) {
        shdapp.state.authToken = '';
        shdapp.state.userStatusResponse = undefined;
        strogging.log('signOut in FE: shdapp', shdapp.state);
      }
    }

    strogging.log('doSignOut completed');
  };

  // *** Game API ***

  createScoreboard = (
    scoreGameKey: string,
    info: {
      scoreTeamId: string;
      shdTeamLineup: shd.UnknownModel[];
      doc: shd.UnknownModel;
      numWrites?: number;
    },
    callback: (err?: Error | null) => void
  ) => {
    const { scoreTeamId, shdTeamLineup, doc: existing, numWrites } = info;
    const now = Date.now() / 1000;
    try {
      const scoreDocRef = doc(
        this.teamGameScoreboardCollection(scoreTeamId),
        scoreGameKey
      );

      setDoc(
        scoreDocRef,
        {
          ...existing,
          lineup: shdTeamLineup,
          modifiedTs: now,
          serverTs: serverTimestamp(),
          createdBy: 'sscore-v001',
          numWrites: numWrites || numWrites === 0 ? numWrites + 1 : 0,
        },
        { merge: true }
      );
    } catch (err) {
      callback(err as Error);
    } finally {
      try {
        // TODO: why is this here?
        if (shdTeamLineup) {
          const scoreDocRef = doc(this.scoringCollection, scoreTeamId);
          setDoc(scoreDocRef, { lineup: shdTeamLineup });
        }
      } catch (err) {
        callback(err as Error);
      } finally {
        callback();
      }
    }
  };

  updateGame = (
    teamId: string,
    gameId: string,
    game: Partial<docStorage.GameDoc>
  ) => {
    const gameDocRef = doc<docStorage.GameDoc, docStorage.GameDoc>(
      this.teamGameCollection(teamId),
      gameId
    );
    return updateDoc(gameDocRef, game);
  };

  updateEventHistory = (
    teamId: string,
    scoreGameKey: string,
    eventHistoryCompressed: string,
    callback: (err?: Error | null) => void
  ) => {
    const now = Date.now() / 1000;
    const scoreGameRef = doc(
      this.teamGameScoreboardCollection(teamId),
      scoreGameKey
    );

    updateDoc(scoreGameRef, { eventHistoryCompressed, modifiedTs: now })
      .then(() => callback())
      .catch(callback);

    getDoc(scoreGameRef)
      .then((d) => {
        if (d.exists()) {
          strogging.log('Document data:', d.data());
          if ('eventHistory' in d.data()) {
            updateDoc(scoreGameRef, {
              eventHistory: deleteField(),
            }).then(() => strogging.log('deleted eventHistory'));
          }
        } else {
          strogging.log('No such document!');
        }
      })
      .catch((error) => {
        strogging.error('Error getting document:', error);
      });
  };

  deleteEventHistory = (teamId: string, scoreGameKey: string) => {
    const scoreGameRef = doc(
      this.teamGameScoreboardCollection(teamId),
      scoreGameKey
    );

    getDoc(scoreGameRef)
      .then((d) => {
        if (d.exists()) {
          strogging.log('Document data:', d.data());
          if ('eventHistory' in d.data()) {
            updateDoc(scoreGameRef, {
              eventHistory: deleteField(),
            }).then(() => strogging.log('deleted eventHistory'));
          }
        } else {
          strogging.log('No such document!');
        }
      })
      .catch((error) => {
        strogging.error('Error getting document:', error);
      });
  };

  subscribeToGame = (
    teamID: string,
    gameId: string,
    callback: (
      snapshotData: docStorage.ScoreboardDoc | null,
      err: Error | null
    ) => void
  ) => {
    try {
      const gameQuery = query(
        this.teamGameScoreboardCollection(teamID),
        where('scoreGameId', '==', gameId)
      );

      onSnapshot(
        gameQuery,
        (querySnapshot) => {
          const snapshots: QueryDocumentSnapshot<docStorage.ScoreboardDoc>[] =
            [];
          querySnapshot.forEach((d) => {
            snapshots.push(d);
          });
          if (snapshots.length === 0) {
            return callback(null, null);
          }
          return callback(snapshots[0].data(), null);
        },
        (error) => {
          strogging.error('subscribeToGame error', error);
        }
      );
    } catch (err) {
      callback(null, err as Error);
    }
  };

  subscribeToTeam = (
    teamID: string,
    callback: (
      snapshotData: docStorage.TeamDoc | undefined,
      err: Error | null
    ) => void
  ) => {
    try {
      const teamDocRef = doc(this.teamCollection, teamID);

      onSnapshot(
        teamDocRef,
        (snapshot) => callback(snapshot.data(), null),
        (error) => {
          strogging.error('subscribeToTeam error', error);
        }
      );
    } catch (err) {
      callback(undefined, err as Error);
    }
  };

  unsubscribeFromGame = (teamId: string, gameId: string) => {
    try {
      const gameQuery = query(
        collection(this.scoringCollection, teamId, 'scoreboards'),
        where('_id', '==', gameId)
      );

      return onSnapshot(
        gameQuery,
        () => {},
        (error) => {
          strogging.log('unsubscribeFromGame', error);
        }
      );
    } catch (err) {
      return err;
    }
  };
}

let theFirebaseImpl = new Firebase();

const reinitializeFirebase = () => {
  theFirebaseImpl = new Firebase();
};
const theFirebase = () => theFirebaseImpl;

export { theFirebase, reinitializeFirebase };
