import React, { useContext } from 'react';
import { errorUtil, strogging } from '@shd/jslib/infra';
import { theFirebase } from './Firebase/firebase';
import { UserContext } from './Session';
import { appWeb } from '@shd/jslib/ui';
import { shd } from '@shd/jslib/models';

export const AppHostContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const userStore = useContext(UserContext);
  const [appHost, setAppHost] = React.useState<AppHostMode>(
    window.shdApp && window.ReactNativeWebView
      ? { isHosted: true, service: appHostServiceProxy, initState: 'none' }
      : { isHosted: false }
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setAppHost(
      window.shdApp && window.ReactNativeWebView
        ? { isHosted: true, service: appHostServiceProxy, initState: 'none' }
        : { isHosted: false }
    );
    // vector strogging back to the app
    if (window.ReactNativeWebView && window.shdApp) {
      strogging.setOptions({
        callConsole: false,
        listener: appHostServiceProxy.notifications.notifyLogging,
      });
      (async () => {
        const shdapp = window.shdApp;
        if (!shdapp) {
          return;
        }
        try {
          // authenticate
          if (shdapp.state.authToken) {
            strogging.log('Authenticating with custom token');
            setLoading(true);
            await theFirebase().doSignInWithCustomToken(shdapp.state.authToken);
            setLoading(false);
          }
          setAppHost({
            isHosted: true,
            service: appHostServiceProxy,
            initState: 'auth',
          });

          if (shdapp.state.userStatusResponse) {
            await userStore.setFromUserStatusResponse(
              shdapp.state.userStatusResponse
            );
          }
          if (shdapp.state.selectedTeamId) {
            const teamIds = userStore.userTeams.map((team) => team._id);
            const index = teamIds.indexOf(shdapp.state.selectedTeamId);
            userStore.setSelectedTeam(userStore.userTeams[index]);
          }
        } catch (e) {
          strogging.error('Error in AppHostContextProvider', {
            err: errorUtil.errorMessage(e),
          });
        } finally {
          setAppHost({
            isHosted: true,
            service: appHostServiceProxy,
            initState: 'store',
          });
        }
      })();
    }
  }, [userStore]);
  return (
    <AppHostContext.Provider value={appHost}>
      {loading ? null : children}
    </AppHostContext.Provider>
  );
};

type AppHostMode =
  | {
      isHosted: true;
      service: appWeb.AppHostService;
      initState: 'none' | 'auth' | 'store' | 'unknown';
    }
  | { isHosted: false };

const appHostServiceProxy: appWeb.AppHostService = {
  notifications: {
    notifyLogging: (
      level: strogging.LoggingLevel,
      message: string,
      payload?: unknown
    ): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'notifyLogging',
          data: [level, message, payload],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    appGoLive: (teamId: string): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'appGoLive',
          data: [teamId],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    signOut: (): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'signOut',
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    trackEvent: (eventName: string, payload?: unknown): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'trackEvent',
          data: [eventName, payload],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    identifyUser: (unique_id?: string): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'identifyUser',
          data: [unique_id],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    setUserProperties: (prop: Record<string, unknown>): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'setUserProperties',
          data: [prop],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    userCancelledYoutubeAuth: (): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'userCancelledYoutubeAuth',
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    showPaywall: (): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'showPaywall',
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    navigateTo: (screen: string): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'navigateTo',
          data: [screen],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    openAppLink: (url: string): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'openAppLink',
          data: [url],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    saveToCameraRoll: (url: string, fileName: string): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'saveToCameraRoll',
          data: [url, fileName],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
    setAuthFromPhoneSignIn: (
      token: string,
      phoneAuthCredential?: shd.PhoneAuthCredential
    ): void => {
      const msg: appWeb.AppHostMessage = {
        msgType: 'notification',
        notification: {
          name: 'setAuthFromPhoneSignIn',
          data: [token, phoneAuthCredential],
        },
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
    },
  },
  requests: {
    authenticateFacebook: (): Promise<appWeb.AppHostFacebookAuthResponse> => {
      return createAppHostRequestPromise('authenticateFacebook', []);
    },
    getFacebookAuthResponse:
      (): Promise<appWeb.AppHostFacebookAuthResponse> => {
        return createAppHostRequestPromise('getFacebookAuthResponse', []);
      },
    authorizeYoutube: (
      teamId: string
    ): Promise<appWeb.AppHostYoutubeAuthResponse> => {
      return createAppHostRequestPromise('authorizeYoutube', [teamId]);
    },
    signInWithProviderId: (
      providerId: string
    ): Promise<appWeb.AppHostSignInWithProviderIdResponse> => {
      return createAppHostRequestPromise('signInWithProviderId', [providerId]);
    },
    fetchOS: (): Promise<string> => {
      return createAppHostRequestPromise('fetchOS', []);
    },
  },
};

export const AppHostContext = React.createContext<AppHostMode>({
  isHosted: false,
});

export const theGlobalAppHost = (): AppHostMode =>
  window.shdApp && window.ReactNativeWebView
    ? { isHosted: true, service: appHostServiceProxy, initState: 'unknown' }
    : { isHosted: false };

let nextRequestId = 0;
const pendingRequestMap = new Map<
  number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { resolve: (response: any) => void; reject: (error: unknown) => void }
>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
window.shdAppResolvePendingRequest = (requestId: number, response: any) => {
  strogging.log('shdAppResolvePendingRequest', { requestId, response });
  if (pendingRequestMap.has(requestId)) {
    pendingRequestMap.get(requestId)?.resolve(response);
    pendingRequestMap.delete(requestId);
  } else {
    strogging.error('shdAppResolvePendingRequest: Request not found', {
      requestId,
    });
  }
};

window.shdAppRejectPendingRequest = (requestId: number, error: unknown) => {
  strogging.log('shdAppRejectPendingRequest', { requestId, error });
  if (pendingRequestMap.has(requestId)) {
    pendingRequestMap.get(requestId)?.reject(error);
    pendingRequestMap.delete(requestId);
  } else {
    strogging.error('shdAppRejectPendingRequest: Request not found', {
      requestId,
    });
  }
};
function createAppHostRequestPromise<TResult>(
  name: keyof appWeb.AppHostService['requests'],
  args: appWeb.MessageRequestDataTypes[keyof appWeb.AppHostService['requests']]
): Promise<TResult> {
  return new Promise((resolve, reject) => {
    const requestId = nextRequestId++;
    pendingRequestMap.set(requestId, { resolve, reject });
    const msg: appWeb.AppHostMessage = {
      msgType: 'request',
      requestId,
      request: { name, data: args } as appWeb.AppHostRequest,
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(msg));
  });
}
