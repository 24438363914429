import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Text, Heading, TextInput, Button } from 'grommet';
import * as Sentry from '@sentry/browser';
import PhoneNumberInput from '../../helpers/PhoneNumberInput';
import { UserContext } from '../../services/Session';
import axios from '../../helpers/axios';
import PhoneNumberConsent from '../../components/PhoneNumberConsent';

interface Props {
  currentStep: number;
  afterValidation: () => void;
  inviteCode?: string;
}
const AccountInfo: React.FC<Props> = observer(
  ({ currentStep, afterValidation, inviteCode }) => {
    const userStore = useContext(UserContext);

    const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
    const [phoneError, setPhoneError] = useState(false);
    const [email, setEmail] = useState<string | undefined>();
    const [error, setError] = useState<string | undefined>();

    let tokenizedName;
    if (userStore.authUser?.displayName) {
      tokenizedName = userStore.authUser.displayName.split(' ');
    } else {
      tokenizedName = [
        userStore.user.nameFirst || '',
        userStore.user.nameLast || '',
      ];
    }
    const [nameFirst, setNameFirst] = useState(
      tokenizedName.length > 0 ? tokenizedName[0] : ''
    );
    const [nameLast, setNameLast] = useState(
      tokenizedName.length > 1 ? tokenizedName[1] : ''
    );

    const unknownName =
      nameFirst.toLocaleLowerCase() === 'unknown' ||
      nameLast.toLocaleLowerCase() === 'signin';

    if (currentStep !== 0) {
      return null;
    }

    const onValidPhone = (value: string) => {
      if (value) {
        setPhoneNumber(value);
        setPhoneError(false);
      } else {
        setPhoneNumber(undefined);
      }
    };

    const onErrorPhone = (e: string) => {
      if (e) {
        setPhoneError(true);
        setPhoneNumber(undefined);
      } else {
        setPhoneError(false);
      }
    };

    const validate = () => {
      const newUser = {
        nameFirst,
        nameLast,
      } as {
        nameFirst: string;
        nameLast: string;
        phoneList?: string[];
        email?: string;
      };

      if (phoneNumber) {
        newUser['phoneList'] = [phoneNumber];
      }
      if (email) {
        newUser['email'] = email.trim().toLowerCase();
      }
      let body;
      if (inviteCode) {
        body = { newUser, inviteCode };
      } else {
        body = { newUser };
      }

      axios
        .put(`/api/user/${userStore.authUser?.claims?.shd_user_id}`, body)
        .then(() => {
          if (phoneNumber) {
            userStore.setUserPhone(phoneNumber);
          }
          afterValidation();
        })
        .catch((respError) => {
          Sentry.captureException(respError);
          setError('Something went wrong. Please refresh and try again.');
        });
    };

    const nextButtonProps = {
      primary: true,
      size: 'large',
      label: 'Next',
      onClick: validate,
      disabled: !phoneNumber || phoneError,
    };

    return (
      <Box pad={{ horizontal: 'large' }} gap="medium" width="large">
        <Heading level="3">{`Welcome to sidelineHD 👋`}</Heading>
        {unknownName ? null : (
          <Box gap="small">
            <Text size="large">What is your name?</Text>
            <Box>
              <Text size="small" weight={'bold'}>
                First Name
              </Text>
              <TextInput
                autoComplete="given-name"
                value={nameFirst}
                onChange={(e) => setNameFirst(e.target.value)}
              />
            </Box>
            <Box>
              <Text size="small" weight={'bold'}>
                Last Name
              </Text>
              <TextInput
                autoComplete="family-name"
                value={nameLast}
                onChange={(e) => setNameLast(e.target.value)}
              />
            </Box>
          </Box>
        )}
        {error ? <Text color="status-critical">{error}</Text> : null}
        {!userStore.userPhone && (
          <Box margin={{ top: 'xlarge' }} gap="small">
            <Text size="large">
              We send text messages for notifications about your livestreams.
            </Text>
            <Text size="small" weight={'bold'}>
              Phone Number
            </Text>
            <PhoneNumberInput
              value={userStore.userPhone}
              id="phone-input"
              placeholder="Your number"
              onValid={onValidPhone}
              onError={onErrorPhone}
            />
            {phoneError ? (
              <Text color="status-critical">
                Please enter a valid phone number.
              </Text>
            ) : null}
            <PhoneNumberConsent acceptButtonProps={nextButtonProps} />
          </Box>
        )}
        {!userStore.user.email && (
          <Box margin={{ top: 'xlarge' }} gap="medium">
            <Box gap="small">
              <Text size="large">What is your email?</Text>
              <TextInput
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <Button
              primary
              size="large"
              label="Next"
              disabled={!email?.includes('@')}
              onClick={validate}
            />
          </Box>
        )}
      </Box>
    );
  }
);

export default AccountInfo;
