import { useContext } from 'react';
import { AppHostContext } from '../services/appHost';
import { strogging } from '@shd/jslib/infra';
import { ClipFeedClip } from '../features/Player/player.types';

const useDownloadClips = () => {
  const appHost = useContext(AppHostContext);

  const isAppHosted = () => {
    return appHost.isHosted;
  };
  const downloadClips = async (clipObj: ClipFeedClip, isPreview: boolean) => {
    const clipUrl = isPreview
      ? clipObj.fileDownloadUrl
      : clipObj.fileDownloadUrlFull;

    const fileName =
      clipObj?.textDescription?.toLowerCase().replace(/ /g, '_') ||
      clipObj.textDescriptionBrief;

    if (appHost.isHosted) {
      // in-app
      appHost.service.notifications.saveToCameraRoll(
        clipObj.fileDownloadUrl || '',
        fileName
      );
      return clipUrl;
    } else {
      // web-browser
      try {
        const res = clipUrl && (await fetch(clipUrl));
        if (res && res.ok) {
          const blob = await res?.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download =
            clipObj?.textDescription?.toLowerCase().replace(/ /g, '_') ||
            clipObj.textDescriptionBrief;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          return url;
        }
      } catch (error) {
        strogging.log('Download failed:', error);
        return '#';
      }
    }
  };

  return { downloadClips, isAppHosted };
};

export default useDownloadClips;
