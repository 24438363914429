export const ADMIN = '/admin';
export const ADMIN_TEAM = '/admin/team/:teamId';
export const ADMIN_PLAYER = '/admin/player/:playerId';
export const ADMIN_USER = '/admin/user/:userId';
export const ADMIN_MERGE_PLAYERS = '/admin/merge';
export const ADMIN_RELABEL_CLIPS = '/admin/relabel';
export const ADMIN_SEARCH = '/admin/search';

export const COACH = '/coach';
export const COMPILATION = '/cp/:compilation_id';
export const EULA = '/eula';
export const GAME = '/game/:gameId';
export const HOME = '/home';
export const LANDING = '/';
export const ROSTER = '/roster';
export const ONBOARDING = '/onboarding';
export const SETTINGS = '/settings';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const PHONE_SIGN_IN = '/phone_signin';
export const STREAM = '/stream';
export const ADMIN_STREAMBOARD = '/adm/streamboard';
export const ADMIN_STREAMLOG = '/adm/streamlog';
export const PRO_STREAMBOARD = '/adm/pro_streamboard';
export const SUPPORT = '/support';
export const GAME_LISTENER = '/temp_game_listener';
export const SCORING = '/scoring/:teamid?';
export const STREAMING_CONFIG = '/streaming';
export const SIDELINE_CAM = '/sidelinecam';
export const CLAIM_USER = '/claim_user/:userid';
export const CLAIM_PLAYER = '/claim_player/:teamplayerid';
export const CLAIM_PLAYER_HOME = '/claim_player';
export const BETA_INFO = '/betainfo';
export const INVITED_USER = '/invite'; // for invited team managers, livestreamers, coaches etc
export const STREAMING_INFO = '/streaminginfo';
export const PRIVACY_POLICY = '/privacy';
export const TERMS_OF_SERVICE = '/terms-of-service';
export const ATTRIBUTIONS = '/attributions';
export const WAITING = '/waiting';
export const TEAM_SEARCH = '/search';
export const HIGHLIGHT = '/hr/:highlight_reel_id';
export const TOP_PLAYERS = '/top-players/:sport/:region';
export const HITSCOPE = '/hitscope/:playerid';
export const TEAMS_HITSCOPE = '/hitscope/teams/:teamids';
export const GAME_HITSCOPE = '/hitscope/game/:gameid';
export const PLAYLIST = '/playlist/:playlistId';
export const REMOTE_LOGIN_AUTHORIZE = '/remote_login_authorize';
export const APP_GOLIVE_TEAM = '/app/golive/team/:teamid';
export const LIVESTREAM = '/livestream';

export const ENABLE_LIVESTREAMER = '/admin/enable_livestreamer';

export const GAMECHANGER_IMPORT = '/import-gc-team';
export const GET_STARTED = '/get-started';
export const GET_STARTED_VB = '/get-started-vb';
export const GET_STARTED_GENERIC = '/get-started-sports';

export const PARTNER_REFERRAL = '/r/:referralcode';

export const STOP_IMPERSONATION = '/stop_impersonation';

export const MLB_SCORES_OVERLAY = '/overlays/mlb_scores';
export const HITMATCH = '/hitmatch/:id';

export const TEAMSNAP_CALLBACK = '/teamsnap/callback';
export const VIDEO_PLAYLIST = '/reels/:playlistId';

export const TEAM_SETTINGS = '/team_settings';

export const TEAM_SETTINGS__STREAM_MENU = '/team_settings/stream';
export const TEAM_SETTINGS__PUBLISHING = '/team_settings/publishing';
export const TEAM_SETTINGS__CAMERA = '/team_settings/camera';
export const TEAM_SETTINGS__RADAR = '/team_settings/radar';

export const TEAM_SETTINGS__TEAM_STAFF = '/team_settings/staff';

export const TEAM_SETTINGS__TEAM_PROFILE_MENU = '/team_settings/profile';
export const TEAM_SETTINGS__TEAM_INFORMATION = '/team_settings/information';
export const TEAM_SETTINGS__SPONSORS = '/team_settings/sponsors';
export const TEAM_SETTINGS__PRIVACY = '/team_settings/privacy';

export const AUTH0_LOGIN = '/auth0_login';
export const AUTH0_FIREBASE_CALLBACK = '/auth0_firebase_callback';
export const DK_PLAYER_RECOMMENDATIONS = '/player/:playerId/recommendations';

export const AUTHORIZE_YOUTUBE = '/authorize_youtube/:teamId';

export const SIDELINE_LIVE = '/live/:teamId';
export const SIDELINE_LIVE_HANDLE = '/live/:teamHandle';

export const SIDELINE_LIVE_OG = '/:teamId/live';

export const SETTINGS__SUBSCRIPTION = '/settings/subscription';
